import React from "react";
import PropTypes from "prop-types";
import "./Button.css";
import Spinner from "../spinner/Spinner";

const Button = ({ id, text, onClick, disabled, color, className, type, loading }) => {
    return (
        <button
        className={`button ${color ? `button-${color}` : ""} ${disabled ? "button-disabled" : ""} ${className}`}
            onClick={onClick}
            disabled={disabled}
            type={type}
            id={id}
        >
        {loading ? (
            <Spinner/>
        ) : (
            text
        )}
        </button>
    );
}

Button.propTypes = {
    id: PropTypes.number,
    text: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    severity: PropTypes.oneOf(["red", "blue", "green"]),
    className: PropTypes.string,
    type: PropTypes.string,
    loading: PropTypes.bool
};

Button.defaultProps = {
    text: "Button",
    onClick: () => {},
    disabled: false,
    severity: "red",
    className: "",
    type: "button",
    loading: false
};

export default Button;
