import React from "react";
import "./Breadcrumbs.css";
import { Link } from "react-router-dom";
const Breadcrumbs = ({breadcrumbs}) => {
    const renderBreadcrumbs = () => {
        if(!breadcrumbs || breadcrumbs.length === 0) {
            return <Link className="breadcrumb-item" to={"/profile/admin"}>Ga terug</Link>
        } else {
            return breadcrumbs.map((breadcrumb, index) => {
                return (
                    <React.Fragment key={index}>
                        <Link className="breadcrumb-item" to={breadcrumb.link}>{breadcrumb.name}</Link>
                        {index < breadcrumbs.length - 1 && <span className="breadcrumb-separator">/</span>}
                    </React.Fragment>
                );
            });
        }
    }

    const renderBreadcrumbLink = () => {
        // get last item of breadcrumb array
        if(breadcrumbs && breadcrumbs.length > 0) {
            if(breadcrumbs[breadcrumbs.length - 2]){
                return breadcrumbs[breadcrumbs.length - 2].link;
            } else {
                return breadcrumbs[breadcrumbs.length - 1].link;
            }
        } else {
            return "/";
        }
    }

    return (
        <div className="breadcrumbs">
            <Link className="breadcrumbs-arrow" to={renderBreadcrumbLink()}></Link>
            {renderBreadcrumbs()}
        </div>
    );
}

export default Breadcrumbs;