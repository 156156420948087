import React from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import { useTranslation } from 'react-i18next';
import './Privacy.css';

const Privacy = () => {
  // we halen t op van de useTranslation hook
  const { t } = useTranslation();
  // deze t bevat de vertalingen van de huidige geselecteerde taal

  return (
    <DefaultLayout>
      <>
        <h1 className="HeadTitle">{t("privacy.title")}</h1>

        <p>{t("privacy.date")}</p>
        <p>{t("privacy.welcome")} </p>
        <p>{t("privacy.goal")}:</p>

        <ul className="list">
          <li>{t("privacy.persoonlijke_informatie.1")}</li>
          <li>{t("privacy.persoonlijke_informatie.2")}</li>
          <li>{t("privacy.persoonlijke_informatie.3")}</li>
          <li>{t("privacy.persoonlijke_informatie.4")}</li>
        </ul>

        <h2>{t("privacy.1.title")}</h2>
        <h3 className="paragraph">{t("privacy.1.1-1.title")}:</h3>
        <p>{t("privacy.1.1-1.text")}</p>

        <h2>{t("privacy.2.title")}</h2>
        <h3 className="paragraph">{t("privacy.2.2-1.title")}:</h3>
        <p>{t("privacy.2.2-1.text")}</p>

        <h3 className="paragraph">{t("privacy.2.2-2.title")}:</h3>
        <p>{t("privacy.2.2-2.text")}</p>

        <h3 className="paragraph">{t("privacy.2.2-3.title")}:</h3>
        <p>{t("privacy.2.2-3.text")}</p>

        <h3 className="paragraph">{t("privacy.2.2-4.title")}:</h3>
        <p>{t("privacy.2.2-4.text")}</p>

        <h2>{t("privacy.3.title")}</h2>
        <h3 className="paragraph">{t("privacy.3.3-1.title")}:</h3>
        <p>{t("privacy.3.3-1.text")}</p>

        <h3 className="paragraph">{t("privacy.3.3-2.title")}:</h3>
        <p>{t("privacy.3.3-2.text")}</p>

        <h2>{t("privacy.4.title")}</h2>
        <h3 className="paragraph">{t("privacy.4.4-1.title")}:</h3>
        <p>{t("privacy.4.4-1.text")}</p>

        <h3 className="paragraph">{t("privacy.4.4-2.title")}:</h3>
        <p>{t("privacy.4.4-2.text")}</p>

        <h2>{t("privacy.5.title")}</h2>
        <p>{t("privacy.5.text")}</p>

        <h2>{t("privacy.6.title")}</h2>
        <p>{t("privacy.6.text")}</p>

        <h2>{t("privacy.7.title")}</h2>
        <p>{t("privacy.7.text")}</p>
      </>
    </DefaultLayout>
  );
};

export default Privacy;