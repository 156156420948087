import React from "react";
import "./List.css";
import Delete from "../../assets/icons/delete.svg";

const List = ({ data, fields, onClick }) => {
    return (
        <div className="list-container">
            {data.map((item, index) => {
                return (
                    <div key={index} className="list-item">
                        {fields.map((field, i) => (
                            <p key={i}>{item[field]}</p>
                        ))}
                        <button className="formAddButton delete-block-button" type="button" onClick={() => onClick(item.group_id)}>
                            <img
                            src={Delete} 
                            title="Verwijder blok"
                            alt="Verwijder blok"
                            />
                        </button>
                    </div>
                );
            })}
        </div>
    );
};

export default List;