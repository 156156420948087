import environment from '../environment.json';

const API_BASE_URL = process.env.REACT_APP_API_URL;

export async function getAssignments(token) {
    try {
        const response = await fetch(`${API_BASE_URL}/assignment`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        if(!response.ok) {
            throw new Error('Er was een probleem bij het ophalen van de gegevens.');
        }
        return response.json();
    } catch (error) {
        throw error;
    }
}

export async function getAssignmentDetails(assignmentId, token) {
    try {
        const response = await fetch(`${API_BASE_URL}/assignment_details?assignment_id=${assignmentId}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        if(!response.ok) {
            throw new Error('Er was een probleem bij het ophalen van de gegevens.');
        }
        return response.json();
    } catch (error) {
        throw error;
    }
}

export async function postAssignment(formData, token) {
    try {
        const response = await fetch(`${API_BASE_URL}/assignment?form_id=${formData.selectedForm}&name=${formData.name}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(formData.selectedGroups.map((group) => group.group_id))
        });
        if(!response.ok) {
            throw new Error('Er is een probleem opgetreden bij het toevoegen van de assignment. Probeer het later opnieuw.');
        }
        return response.json();
    } catch (error) {
        throw error;
    }
}

export async function subscribe(assignmentId, groupId, token) {
    try {
        const response = await fetch(`${API_BASE_URL}/subscribe`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({ assignment_id: assignmentId, group_id: groupId})
        });
        if(!response.ok) {
            throw new Error('Er is een probleem opgetreden bij het inschrijven. Probeer het later opnieuw.');
        }
        return response.json();
    } catch (error) {
        throw error;
    }
}

export async function deleteAssignment(assignmentId, token) {
    try {
        const response = await fetch(`${API_BASE_URL}/assignment/${assignmentId}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        if(!response.ok) {
            throw new Error('Er is een probleem opgetreden bij het verwijderen van de assignment. Probeer het later opnieuw.');
        }
    } catch (error) {
        throw error;
    }
}

export async function putAssignment(assignmentId, token) {
    try {
        const response = await fetch(`${API_BASE_URL}/assignment?assignment_id=${assignmentId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        });
        if(!response.ok) {
            throw new Error('Er is een probleem opgetreden bij het bijwerken van de assignment. Probeer het later opnieuw.');
        }
    } catch (error) {
        throw error;
    }
}
