import React, { useContext } from "react";
import { VragenlijstContext } from "../../../contexts/VragenlijstContext";
import { removeCookie } from "../../../services/localAnsweredQuestionsService";
import Button from "../../button/Button";
import { useTranslation } from 'react-i18next';


const VragenlijstDrawer = () => {
    const {name, blocks, isCompleted, currentBlock, changeCurrentBlock, getUnansweredQuestions} = useContext(VragenlijstContext);
    const renderUnansweredQuestions = () => {
        if(isCompleted){
           return  t('vragenlijstDrawer.completed') ; 
        } else {
            return  t('vragenlijstDrawer.unanswered1')  + getUnansweredQuestions() + t('vragenlijstDrawer.unanswered2');
        }
    }
    const { t } = useTranslation();

    const removeAnswerData = () => {
        removeCookie();
        window.location.reload();
    }

    return (
        <div className='drawer__content'>
            {name && <p className='drawer-vragenlijstNaam'>{name}</p>}
            <p className='drawer-unansweredQuestions'>{renderUnansweredQuestions()}</p>
            <ul className='drawer__blockItems'>
                {blocks.map ((block, index) => {
                    return (
                        <li onClick={() => changeCurrentBlock(index)} className={'drawer__blockItem ' + (block.block_id === currentBlock.block_id && "drawer__blockItem--selected")} key={index}>
                            <h3>{block.name}</h3>
                        </li>
                    )
                })}
            </ul>
            <Button text={t('vragenlijstDrawer.delAnswer')} onClick={() => removeAnswerData()} className="button-small"  />
        </div>
    );
    }

export default VragenlijstDrawer;