import React, { useContext, useEffect, useState } from "react";
import "./AdminSuite.css"
import ProfileLayout from "../../../../layouts/ProfileLayout";
import Breadcrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import { getRoles, putUserRole } from "../../../../services/userService";
import Button from "../../../../components/button/Button";
import { AuthContext } from "../../../../contexts/AuthContext";
import { useTranslation } from 'react-i18next';
import { ToastContext } from "../../../../contexts/ToastContext";


const Users = () => {
    const breadcrumbs = [ {link: "/profile/admin", name: "Admin"}, {link: "/profile/admin/users", name: "Users"} ];
    // GENERAL
    const { token } = useContext(AuthContext);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(true);
    const { setToastInstance } = useContext(ToastContext);
    // DATA
    const [roles, setRoles] = useState([]);
    // FORMDATA
    const [uuid, setUuid] = useState("");
    const [selectedRole, setSelectedRole] = useState(null);

    const { t } = useTranslation();


    useEffect(() => {
        fetchRoles();
    }, []);

    const fetchRoles = () => {
        setLoading(true);
        getRoles().then((response) => {
            setRoles(response);
            setLoading(false);
        }).catch((error) => {
            setError(error);
            setLoading(false);
            setSuccess(null);
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        putUserRole(uuid, selectedRole, token).then((response) => {
            setError(null);
            setSuccess("Rol is aangepast");
            setToastInstance({ message: t("toasts.editrole"), type: "success" });
        }).catch((error) => {
            setError(error.message);
            setToastInstance({ message: error.message, type: "error" });
            setSuccess(null);
        });
    }
    return (
        <ProfileLayout>
            <div className="profileContent">
                <Breadcrumbs breadcrumbs={breadcrumbs}/>
                <div className="contentContainer adminSuite-flex">
                    <p className="contentContainer-top">{ t('profile.admin.user.desc') }</p>
                    <form className="change-user-select-form">
                        <input type="text" placeholder={ t('profile.admin.user.uuidType') } className="input uuid" value={uuid} onChange={(e) => setUuid(e.target.value)}/>
                        <select className="input roles" onChange={(e) => setSelectedRole(e.target.value)}>
                            <option value="" selected disabled >{ t('profile.admin.user.roleChose') }</option>
                            {roles && roles.map((role) => (
                                <option key={role.id} value={role.role_id}>{role.name}</option>
                            ))}
                        </select>
                        <Button type="submit" text={ t('profile.admin.user.roleChose') } onClick={handleSubmit} className="button primary" disabled={(uuid.length === 0) || (selectedRole === null)}/>
                    </form>
                    {error && <p className="error">{error}</p>}
                    {success && <p className="success">{success}</p>}
                    <p className="uuid-info">
                    { t('profile.admin.user.uuidText') }
                    </p>
                </div>
            </div>
        </ProfileLayout>
    );
}

export default Users;