import "./Header.css";
import React, { useContext } from 'react';
import { useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import i18n from 'i18next';

function Header({ mini }) {
  if(!mini) mini = false;
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language)
  const location = useLocation();

  useEffect(() => {
    setIsNavOpen(false);
  }, [location]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setCurrentLanguage(lng);
  };

  const handleClickNavToggle = () => {
    setIsNavOpen(!isNavOpen);
  };

  const { currentUser } = useContext(AuthContext);

  return (
    <header className="header">
      <div className={`header__content ${isNavOpen && 'open'}`}>
        <label htmlFor="nav-toggle" className="toggle-button" onClick={handleClickNavToggle}>
          <i className="fa-solid fa-bars fa-xl"></i>
        </label>
        
        <input type="checkbox" id="nav-toggle" className="nav-toggle" />
        <nav className={`navbar ${isNavOpen ? 'navOpen' : 'navClose'}`}>
          <ul className="menu__items">
            {!mini &&
              <>
                <li className="menu__item">
                  <Link className="item__link" to="/">
                    Home
                  </Link>
                </li>
                <li className="menu__item">
                  {
                    currentUser ?  (
                      <Link className="item__link" to="/profile/algemeen">
                        Profile
                      </Link>
                    ) : (
                      <Link className="item__link" to="/sign-in">
                        Login
                      </Link>
                    )
                  }
                </li>
              </>
            }
            <li className="menu__item">
              <select value={currentLanguage} className="item__link language__picker" onChange={(e) => changeLanguage(e.target.value)}>
                <option value="en" disabled={currentLanguage === 'en'}>English</option>
                <option value="nl" disabled={currentLanguage === 'nl'}>Nederlands</option>
                <option value="fr" disabled={currentLanguage === 'fr'}>Français</option>
              </select>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
