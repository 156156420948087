import React, { useContext, useEffect, useState } from "react";
import DefaultLayout from "./DefaultLayout";
import ProfileNav from "../components/profile/ProfileNav";
import { AuthContext } from "../contexts/AuthContext";
import "../pages/profile/Profile.css";

const ProfileLayout = ({ children }) => {
    const { userAdminRole } = useContext(AuthContext);

    return (
        <DefaultLayout>
            <div className='layout-container profileContainer' style={{ maxWidth: userAdminRole ? "" : "60rem" }}>
                <ProfileNav/>
                {children}
            </div>
        </DefaultLayout>
    );
}

export default ProfileLayout;