import './ProfileNavStyle.css'
import accountCircle from "../../../src/assets/icons/account_circle.svg";
import algemeen from "../../../src/assets/icons/settings.svg";
import gegevens from "../../../src/assets/icons/edit_square.svg";
import wachtwoord from "../../../src/assets/icons/lock.svg";
import admin from "../../../src/assets/icons/user-tie-solid.svg";
import { Link, useLocation } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import Button from '../button/Button';
import { useTranslation } from 'react-i18next';
import LogoutIcon from '@mui/icons-material/Logout';

const ProfileNav = () => {
    const { signOut, currentUser, userAdminRole } = useContext(AuthContext);
    const location = useLocation();
    const locationPath = location.pathname.split('/');
    const [activeTab, setActiveTab] = useState(`${locationPath[2]}` || 'algemeen');

    const { t } = useTranslation();
    
    return (
    <nav className="Nav">
        <h1 className='hidden'>{ t("profileNav.profileTab") }</h1>
        <div className="Account">
            <div className="UserInfo">
                <div className="AccountCircle">
                    <div className="Vector">
                        <img src={accountCircle} className="profile-icon" />
                    </div>
                </div>
                <div>
                    <p className="UserName">{currentUser.firstname} {currentUser.name}</p>
                    <p className="UserEmail">{currentUser.email}</p>
                </div>
            </div>
        </div>
       
        <ul className="Navitems">
            <Link to="/profile/algemeen" onClick={() => setActiveTab("algemeen")} className={`NavItem ${activeTab === "algemeen" && 'active'}`}>
                <h3 className="ItemTitle">{ t("profileNav.generalTab") }</h3>
                <div className="ItemIcon">
                    <img src={algemeen} className="icon"  />
                </div>
            </Link>
            <Link to="/profile/data" onClick={() => setActiveTab("data")} className={`NavItem ${activeTab === "data" && 'active'}`}>
                <h3 className="ItemTitle">{ t("profileNav.dataTab")}</h3>
                <div className="ItemIcon">
                    <img src={gegevens}
                    className="icon" />
                </div>
            </Link>
            <Link to="/profile/password" onClick={() => setActiveTab("password")} className={`NavItem ${activeTab === "password" && 'active'}`}>
                <h3 className="ItemTitle">{ t("profileNav.passwordsTab")}</h3>
                <div className="ItemIcon">
                    <img src={wachtwoord} className="icon" />
                </div>
            </Link>

            {userAdminRole && 
                <Link to="/profile/admin" onClick={() => setActiveTab("admin")} className={`NavItem ${activeTab === "admin" && 'active'}`}>
                    <h3 className="ItemTitle">{userAdminRole.name}</h3>
                    <div className="ItemIcon">
                        <img src={admin} className="icon" />
                    </div>
                </Link>
            }

            <div className='button__logout-smallScreen'>
                <h3 className="ItemTitle">{ t("profileNav.passwordsTab")}</h3>
                <div className="ItemIcon">
                    <LogoutIcon onClick={(e) => { e.preventDefault(); signOut(); }} style={{color: "var(--red-color)", width: 35, height: 35}} />
                </div>
            </div>
            <Button text={t("profileNav.logoutButton")} color="red" onClick={(e) => { e.preventDefault(); signOut(); }} className={"button__logout"} />
        </ul>
    </nav>
    );
}

export default ProfileNav;