import React, { useContext, useEffect, useState } from 'react';
import './auth.css';
import { AuthContext } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router';
import Spinner from '../../components/spinner/Spinner';
import Button from '../../components/button/Button';
import { useTranslation } from 'react-i18next';

const Login = ({toggleComponent}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setErrors] = useState(" ");
    const navigate = useNavigate();
    const { t } = useTranslation();

    const {signIn} = useContext(AuthContext);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        await signIn(email,password).then((user) => {
            const requestLink = localStorage.getItem('requestLink');
            if(requestLink){
                navigate(requestLink);
            } else {
                navigate('/');
            }
        }).catch((error) => {
            setErrors(error.message);
        }).finally(() => {
            setLoading(false);
        });
    };  

    return (
            <div className="form-container sign-in-container">
                <form className='form-auth' noValidate onSubmit={handleSubmit}>
                    <h1 className='form-title'>{ t('login.login') }</h1>
                    {/* <div className="social-container">
                    <a href="#" className="social">
                        <i className="fab fa-facebook-f" />
                    </a>
                    <a href="#" className="social">
                        <i className="fab fa-google-plus-g" />
                    </a>
                    <a href="#" className="social">
                        <i className="fab fa-linkedin-in" />
                    </a>
                    </div> */}
                    <input
                    type="email"
                    placeholder={ t('login.email') }
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    />

                    <input
                    type="password"
                    name="password"
                    placeholder={ t('login.password') }
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    />
                    <div className='error'>
                        {
                            error && <span>{error}</span>
                        }
                    </div>
                    <a onClick={() => setErrors("Deze functie is nog niet geimplementeerd")} href="#">{ t('login.forgot') }</a>
                    <Button text={ t('login.login') } type="submit" loading={loading} className={"form-auth-button"}/>
                    <div className="mobile-toggleButtons">
                        <a className='link' onClick={() => toggleComponent("/sign-up")}>{ t('login.register') }</a>
                    </div>
                </form>
            </div>
    );
}

export default Login;
