import environment from '../environment.json';

const API_BASE_URL = process.env.REACT_APP_API_URL; //process.env.REACT_APP_API_URL;

export async function signInUser(email, password) {
    const formData = new URLSearchParams();
    formData.append('username', email);
    formData.append('password', password);
    
    try {
        const response = await fetch(`${API_BASE_URL}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData
        });
        if(response.status === 400) throw new Error('De gebruikersnaam of het wachtwoord is onjuist');

        if(!response.ok){
            console.log(response.status)
            throw new Error('Er is een probleem opgetreden bij het inloggen');
        }

        return response.json();
    } catch (error) {
        throw error;
    }
}

// Get user from database (returns user JSON data or throws error)
export async function getUser(token) {

    try {
        const response = await fetch(`${API_BASE_URL}/user`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`
            } 
        });

        if(response.status === 404){
            throw new Error('De gebruiker is niet gevonden in de database');
        }

        if(!response.ok){
            return null;
            //throw new Error('Er is een probleem opgetreden bij het ophalen van de gebruikergegevens');
        }
        return response.json();
    } catch (error) {
        throw error;
    }
}

export async function signUpUser(userData) {
    try {
        const response = await fetch(`${API_BASE_URL}/user`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData)
        });
    
        if(response.status === 409) throw new Error('De gebruiker met die email bestaat al');

        if(response.status === 422) {
            const errorData = await response.json();
            const problem = errorData.detail[0].loc[1];
            if(problem === 'email') throw new Error('Het emailadres is niet geldig');
            if(problem === 'password') throw new Error('Het wachtwoord is niet geldig');
            if(problem === 'school_id') throw new Error('De school is niet geldig');
        } 

        if(!response.ok) throw new Error('Er is een probleem opgetreden bij het aanmaken van de gebruiker')
        
        return response.json();
    } catch (error) {
        throw error;
    }
}

export async function updatePassword(token, formData) {
    try {
        const response = await fetch(`${API_BASE_URL}/user/update_password`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({
                old_password: formData.currentPassword,
                new_password: formData.newPassword
            })
        });

        if(response.status === 400) throw new Error('Het huidige wachtwoord is onjuist');
        if(response.status === 422) throw new Error('Het nieuwe wachtwoord is niet geldig');
        if(response.status === 404) throw new Error('De gebruiker is niet gevonden in de database');
        if(response.status === 401) throw new Error('Je bent niet geautoriseerd om deze actie uit te voeren');
        if(!response.ok) throw new Error('Er is een probleem opgetreden bij het wijzigen van het wachtwoord');
    } catch (error) {
        throw error;
    }
}
