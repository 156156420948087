import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';


const Notfound = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
            navigate('/');
            window.location.reload();
    }, []);

    return (
        <>
            <div>
                <section>
                    <h1>404</h1>
                    <p>{t("NotFound.part1")} {location.pathname} {t("NotFound.part2")}</p>
                    <p>{t("NotFound.redirect")}</p>
                </section>
            </div>
        </>
    );
}

export default Notfound;