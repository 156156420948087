import React, { useContext } from 'react';
import './CardFormBlock.css';
import { VragenlijstContext } from '../../contexts/VragenlijstContext';
import VragenlijstBlock from './VragenlijstBlock';
import { useTranslation } from 'react-i18next';


const VragenlijstViewBlock = () => {
  const { t } = useTranslation();

    const {currentBlock, blocks, changeCurrentBlock} = useContext(VragenlijstContext);

    const isCurrentBlockLastBlock = () => {
        return blocks.indexOf(currentBlock) === blocks.length - 1;
    }

    const renderCardBlockFooter = () => {
      const currentBlockIndex = blocks.indexOf(currentBlock);
    
      return (
        <div className='cardBlock__footer'>
          {currentBlockIndex > 0 && (
            <span className='cardBlock__back' onClick={() => changeCurrentBlock(currentBlockIndex - 1)}>
              {"< "} {t("vragenlijstAssignmentBlock.back")}
            </span>
          )}
          {isCurrentBlockLastBlock() ? (
            <></>
          ) : (
            <button className='cardBlock__button button' onClick={() => changeCurrentBlock(blocks.indexOf(currentBlock) + 1)}>
              {t("vragenlijstAssignmentBlock.next")}
            </button>
          )}
        </div>
      );
    };

    return (
        <VragenlijstBlock 
          name={currentBlock.name} 
          description={currentBlock.description} 
          questions={currentBlock.questions} 
          buttons={renderCardBlockFooter()}
          blockIndex={blocks.indexOf(currentBlock)}
          editable={false}
        />
    );
}

export default VragenlijstViewBlock;
