import React, { useContext, useEffect, useState } from "react";
import "./ListForm.css";
import FormListCard from "../../../../../components/formCard/FormListCard";
import { AuthContext } from "../../../../../contexts/AuthContext";
import { deleteForm, getForms } from "../../../../../services/formService";
import Spinner from "../../../../../components/spinner/Spinner";
import SearchFilter from "../../../../../components/search-filter/SearchFilter";
import { useNavigate } from "react-router-dom";

const ListForms = () => {
  // GENERAL
  const { token } = useContext(AuthContext);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  // DATA
  const [forms, setForms] = useState([]);
  // FILTERED DATA
  const [filteredForms, setFilteredForms] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [sortingOption, setSortingOption] = useState("A-Z");
  const [formStatus, setFormStatus] = useState("all");

  useEffect(() => {
    fetchTeacherForms();
  }, []);

  const fetchTeacherForms = async () => {
    setLoading(true);
    try {
      const forms = await getForms(token);
      setForms(forms);
      setFilteredForms(forms);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const doDeleteForm = async (formId) => {
    setLoading(true);
    try {
      await deleteForm(formId, token);
      fetchTeacherForms();
      setForms(forms.filter(form => form.form_id !== formId));
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const filterAndSortForms = () => {
    let filteredForms = forms;

    if (searchValue !== "") {
      filteredForms = filteredForms.filter((form) =>
        form.name.toLowerCase().includes(searchValue.toLowerCase())
      );
    }

    // TODO: uncomment when status field is implemented in api
    /*
    if (formStatus !== "All") {
      filteredForms = filteredForms.filter(form => form.status === formStatus);
    }
    */

    if (sortingOption === "A-Z") {
      filteredForms = filteredForms.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    } else if (sortingOption === "Z-A") {
      filteredForms = filteredForms.sort((a, b) =>
        b.name.localeCompare(a.name)
      );
    }

    // Update state with filtered and sorted forms
    setFilteredForms(filteredForms);
  };

  useEffect(() => {
    filterAndSortForms(forms);
  }, [searchValue, sortingOption, formStatus]);

  return (
    <>
      {loading ? (
        <div className="contentContainer-loading">
          <Spinner color={"var(--blue-color)"} size={80} />
        </div>
      ) : (
        <>
          {error && <p className="error">{error}</p>}
          <SearchFilter
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            sortingOption={sortingOption}
            setSortingOption={setSortingOption}
            formStatus={formStatus}
            setFormStatus={setFormStatus}
            reload={fetchTeacherForms}
            navigateToAdd={() => navigate("/profile/admin/forms/add")}
          />
          <div className="formcardItems">
            {filteredForms.map((form) => (
              <FormListCard key={form.form_id} form={form} deleteForm={doDeleteForm} />
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default ListForms;
