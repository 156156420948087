import React, { useEffect, useState } from "react";
import Signup from "./Signup";
import Login from "./Login";
import AuthLayout from '../../layouts/AuthLayout';
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/button/Button";
import { useTranslation } from 'react-i18next';

const Auth = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedComponent, setSelectedComponent] = useState(location.pathname);

  const changeSelectedComponent = (component) => {
    navigate(component);
    setSelectedComponent(component);
  }

  useEffect(() => {
    setSelectedComponent(location.pathname);
  }, [location.pathname]);
  
  const containerClass = `containerAuth ${selectedComponent === "/sign-up" ? "right-panel-active" : ""}`;

  return (
    <AuthLayout>
      <div className="AuthBody">
        <div className={containerClass} id="container">
          <Signup toggleComponent={() => changeSelectedComponent("/sign-up")} />
          <Login toggleComponent={() => changeSelectedComponent("/sign-in")} />
          <div className="overlay-container">
            <div className="overlay">
              <div className="overlay-panel overlay-left">
                <h1>{t('auth.welcomeBack')}</h1>
                <p>{t('auth.welcomeText')}</p>
                <Button
                  text={t('auth.login')}
                  className="ghost"
                  onClick={() => changeSelectedComponent("/sign-in")}
                />
              </div>
              <div className="overlay-panel overlay-right">
                <h1>{t('auth.welcome')}</h1>
                <p>{t('auth.welcomeText')}</p>
                <Button
                  text={t('auth.register')}
                  className="ghost"
                  onClick={() => changeSelectedComponent("/sign-up")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}

export default Auth;