import environment from '../environment.json';

const API_BASE_URL = process.env.REACT_APP_API_URL;

// gets user id from database
export async function getUserId(firebaseUID) {
    try {
        const response = await fetch(`${API_BASE_URL}/user_id?auth_id=${firebaseUID}`, {
            method: 'GET',
        });
        if(response.ok) {
            const uid = await response.json();
            return uid;
        } else {
            throw new Error("error getting user id from database")
        }
    } catch (e) {
        throw new Error("error getting user id from database")
    }
}

export async function createDatabaseUser(firebaseUID, userData) {
    try {
        const response = await fetch(`${API_BASE_URL}/user`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                auth_id: firebaseUID,
                email: userData.email,
                firstname: userData.firstname,
                name: userData.name,
                school_id: userData.school_id,
                school_user_id: userData.schoolId,
                role_id: 2,
                permission_id: userData.permission_id,   
                sex: "m",
            })
            
        });
        
        if(!response.ok){
            throw new Error('db-user-error');
        } 
    } catch (error) {
        console.error(error);
        throw new Error('db-user-error');
    }
}

export async function updateDatabaseUser(userData, token) {
    try {
        const response = await fetch(`${API_BASE_URL}/user`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(userData)
        });
        
        if(!response.ok){
            throw new Error('db-user-error');
        } 
    } catch (error) {
        throw new Error('Er is iets misgegaan bij het updaten van uw account');
    }
}

export async function getPermissions() {
    try {
        const response = await fetch(`${API_BASE_URL}/permissions`, {
            method: 'GET',
        });
        
        if(!response.ok){
            throw new Error('db-user-error');
        } 
        return response.json();
    } catch (error) {
        console.error(error);
        throw new Error('Er is iets misgegaan bij het updaten van uw account');
    }
}

export async function getUsers() {
    try {
        const response = await fetch(`${API_BASE_URL}/users`, {
            method: 'GET',
        });
        
        if(!response.ok){
            throw new Error('db-user-error');
        }
        return response.json();
    } catch (error) {
        console.error(error);
        throw new Error('Er is iets misgegaan bij het updaten van uw account');
    }
}

export async function getRoles() {
    try {
        const response = await fetch(`${API_BASE_URL}/roles`, {
            method: 'GET',
        });
        
        if(!response.ok){
            throw new Error('db-user-error');
        } 
        return response.json();
    } catch (error) {
        console.error(error);
        throw new Error('Er is iets misgegaan bij het updaten van uw account');
    }
}

export async function putUserRole(uuid, roleId, token) {
    try {
        const response = await fetch(`${API_BASE_URL}/user_role?uuid=${uuid}&role_id=${roleId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        });
        if(response.status === 404) throw new Error('Gebruiker niet gevonden');
        if(response.status === 401 || response.status === 403) throw new Error('U heeft niet de juiste rechten om deze actie uit te voeren');
        if(!response.ok) throw new Error('Er is iets misgegaan bij het updaten van de rol van het account');
    } catch (error) {
        throw error;
    }
}
