
import React, { useContext, useEffect, useState } from 'react';
import { addAnswerToCookie } from '../../services/localAnsweredQuestionsService';
import { VragenlijstContext } from '../../contexts/VragenlijstContext';
import { useTranslation } from 'react-i18next';

const Question = ({ id, blockIndex, editable, questionIndex, questionData }) => {
  const { updateQuestionAnswer, getAnswerForQuestion } = useContext(VragenlijstContext);
  const [answer, setAnswer] = useState(null);
  const { t } = useTranslation();
  const options = [t('question.hna'), t('question.ena'), t('question.nnnwa'),  t('question.ea'), t('question.ha')];


  const handleChangeInput = (e) => {
    const optionId = options.indexOf(e.target.value);
    const answer = optionId + 1;
    addAnswerToCookie(blockIndex, questionData.question_id, answer);
    setAnswer(optionId);
    updateQuestionAnswer(blockIndex, questionIndex, answer);
  };
  
  useEffect(() => {
    const answer = getAnswerForQuestion(blockIndex, questionIndex); // Answer (1-5)
    if(answer) setAnswer(answer - 1);// -1 want antwoorden zijn 1-5, maar de optionId's van de input zijn 0-4
  }, []);


  const renderQuestionAnswerInput = () => {
    return options.map((option, index) => (
      <div className='cardBlock__answer' key={index}>
        <label className='answer-label'>
          <input
            checked={answer === options.indexOf(option)}
            onChange={(e) => handleChangeInput(e)}
            className='hidden'
            disabled={!editable}
            type='radio'
            id={id + "-" + index}
            name={questionIndex}
            value={option}
          />
          <span className='option-label'></span>
          {option}
        </label>
      </div>
    ));
  };
  
  return (
    <li className='cardBlock__question' key={questionIndex} id={id}>
      <p className='cardBlock__questionText'>{(questionIndex + 1)}) {questionData.question}</p>
      <div className={'cardBlock__answerContainer ' + (!editable && "disabled")}>
        {renderQuestionAnswerInput()}
      </div>
    </li>
  )
}

export default Question;
