let assignmentId;

export async function initCookie(id) {
    assignmentId = id;

    if (!localStorage.getItem(`${assignmentId}`)) {
        localStorage.setItem(`${assignmentId}`, JSON.stringify({}));
    }
}

/** 
 * Functie om een antwoord toe te voegen aan de cookie
 * - blockIndex: de index van de block in de vragenlijst
 * - questionId: de id van de vraag **(geen index!)**
 * - answer: het antwoord op de vraag (1-5)
 * */ 
export async function addAnswerToCookie(blockIndex, questionId, answer) {
    try {
        const cookieData = JSON.parse(localStorage.getItem(`${assignmentId}`)) || {};

        // als er nog geen data is voor deze block, maak een lege array aan
        if (!cookieData[blockIndex]) {
            cookieData[blockIndex] = [];
        }
        
        // check of er al een antwoord bestaat voor deze vraag
        const existingQuestionIndex = cookieData[blockIndex].findIndex((question) => question.questionId === questionId);
        if (existingQuestionIndex !== -1) {
            cookieData[blockIndex][existingQuestionIndex].answer = answer;
        }
        else {
            cookieData[blockIndex].push({questionId, answer});
        }

        localStorage.setItem(`${assignmentId}`, JSON.stringify(cookieData));
    } catch(error) {
        console.log(error);
    }
}

/**
 * Functie om een antwoord uit de cookie te halen
 * - blockIndex: de index van de block in de vragenlijst
 * - questionId: de id van de vraag **(geen index!)**
 * - RETURN: het antwoord op de vraag (1-5)
 * */
export async function getAnswerForQuestionFromCookie(blockIndex, questionId) {
    try {
        const cookieData = await JSON.parse(localStorage.getItem(`${assignmentId}`)) || {};

        if (cookieData[blockIndex]) {
            const question = await cookieData[blockIndex].find((question) => question.questionId === questionId);
            if (question) return question.answer;
        }
        return null;
    } catch(error) {
        console.log(error);
    }
}

/**
 * Functie om het aantal vragen in de cookie te tellen
 * - RETURN: het aantal beantwoorde vragen
 * */
export function getAmountOfAnsweredQuestions() {
    const cookieData = JSON.parse(localStorage.getItem(`${assignmentId}`)) || {};
    let answeredQuestions = 0;

    for (let block in cookieData) {
        answeredQuestions += cookieData[block].length;
    }

    return answeredQuestions;
}

export function checkIfFormIsInLocalStorage() {
    const cookieData = JSON.parse(localStorage.getItem(`${assignmentId}`)) || {};
    return Object.keys(cookieData).length !== 0;
}

// Function to remove an answer from the cookie object
export function removeCookie() {
    localStorage.removeItem(`${assignmentId}`);
}