import React from "react";
import Question from "./Question";

const VragenlijstBlock = ({name, description, questions, buttons, error, blockIndex, editable}) => {
    return (
        <div className='cardBlock'>
            <h2 className='cardBlock__title'>{name}</h2>
            <p className='cardBlock__description'>{description}</p>
            <ul className='cardBlock__questions'>
                {questions && questions.map((questionData, index) => 
                  <Question id={`${blockIndex}-${index}`} key={`${blockIndex}-${index}`} blockIndex={blockIndex} editable={editable} questionIndex={index} questionData={questionData} /> 
                )}
            </ul>
            {error && <p className='error'>{error}</p>}
            {buttons}
        </div>
  ) ;
}

export default VragenlijstBlock;