
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const PersonalitijdsQuestion = ({ questionIndex, questionData, updateQuestionAnswer }) => {
  const [answer, setAnswer] = useState('');
  const { t } = useTranslation();

  const options = [t('question.hna'), t('question.ena'), t('question.nnnwa'),  t('question.ea'), t('question.ha')];

  const handleChangeInput = (e) => {
    const optionId = options.indexOf(e.target.value);
    setAnswer(optionId);
    updateQuestionAnswer(--questionIndex, optionId); 
  };

  useEffect(() => {

  }, []);

  const renderQuestionAnswerInput = () => {
    return options.map((option, index) => (

      <div className='cardBlock__answer' key={index}>
        <label className='answer-label'>
          <input
            checked={answer === options.indexOf(option)}
            onChange={(e) => handleChangeInput(e)}
            className='hidden'
            type='radio'
            id={option}
            name={questionIndex}
            value={option}
          />
          <span className='option-label'></span>
          {option}
        </label>
      </div>
    ));
  };
  
  return (
    <li className='cardBlock__question' key={questionIndex}>
      <p className='cardBlock__questionText'>{++questionIndex}) {questionData.question}</p>
      <div className={'cardBlock__answerContainer ' }>
        {renderQuestionAnswerInput()}
      </div>
    </li>
  )
}

export default PersonalitijdsQuestion;
