import React from 'react';
import Header from '../components/header/Header';
import logo from "../assets/images/logoVives.png";
import { useTranslation } from 'react-i18next';


/*
    Layout for the authentication pages
    within the container:
    - Header
    - children in main
    - Footer
*/
const AuthLayout = ({ children }) => {
    const { t } = useTranslation();

    return (
        <div className='container auth-container'>
            <Header mini={true}/>
            <main className='main-auth'>{children}</main>
            <div className="authFooter">
                <img src={logo} />
            <p>{t("footer.disclamer")} <a href="https://vivesweb.be/digitaalsamenleren">https://vivesweb.be/digitaalsamenleren</a></p>
          </div>
        </div>
    );
};

export default AuthLayout;
