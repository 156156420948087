import React, { useContext, useEffect, useState } from 'react';
import './Home.css';
import { AuthContext } from '../../contexts/AuthContext';
import { getUserForms } from '../../services/formService';
import FormCard from '../../components/formCard/FormCard';
import DefaultLayout from '../../layouts/DefaultLayout';
import { useTranslation } from 'react-i18next';
import AdminsuiteItem from '../../components/adminSuite/AdminsuiteItem';
import assignment from "../../assets/icons/assignment.svg";
import formsIcon from "../../assets/icons/forms.svg";
import grade from "../../assets/icons/grade.svg";
import groups from "../../assets/icons/groups.svg";
import users from "../../assets/icons/personSearch.svg";
import { useNavigate } from 'react-router-dom';

const Home = () => {
    const {token, userAdminRole} = useContext(AuthContext);
    const [forms, setForms] = useState([]);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        if(token && userAdminRole.role_id === 1){
            getUserForms(token).then((res) => {
                setForms(res);
            }).catch((err) => {
                console.log(err);
            })
        }
    }, [token])

    const renderHomeInterface = () => {
        if(userAdminRole.role_id === 1){
            return (
                forms && forms.length > 0 &&
                    <>
                        <div className='centered__container'>
                            <h2>{ t('home.choose') }</h2>
                            <hr style={{height: "1px", width: "100%"}} />
                        </div>
                        <div className='formsContainer'>
                            {forms.map((form) => (
                                <>
                                    <FormCard form={form}/>
                                </>
                            ))}
                        </div>
                    </>
            );
        } else if(userAdminRole.role_id === 2){
            return (
                <div className='centered__container'>
                    <h3 className="adminSuite-groupTitle">{t("home.teacher")}</h3>
                    <div style={{display: "flex", gap:"2rem", flexWrap: "wrap", justifyContent: "center"}}>
                        <AdminsuiteItem logo={groups} itemName="groups" title={t("profile.admin.dashboard.groups")} onClick={() => navigate("/profile/admin/users")}/>
                        <AdminsuiteItem logo={formsIcon} itemName="forms" title={t("profile.admin.dashboard.forms")} onClick={() => navigate("/profile/admin/forms")}/>
                        <AdminsuiteItem logo={assignment} itemName="assignments" title={t("profile.admin.dashboard.assignments")} onClick={() => navigate("/profile/admin/assignments")}/>
                    </div>
                </div>
            );
        } else if(userAdminRole.role_id === 3){
            return (
                <div className='centered__container'>
                    <h3 className="adminSuite-groupTitle">{t("home.researcher")}</h3>
                    <div style={{display: "flex", gap:"2rem", flexWrap: "wrap", justifyContent: "center"}}>
                        <AdminsuiteItem logo={grade} itemName="data" title={t("profile.admin.dashboard.score")} onClick={() => navigate("/profile/admin/data")}/>
                    </div>
                </div>
            );
        } else if(userAdminRole.role_id === 4 || userAdminRole.role_id === 5){
            return (
                <div className='centered__container'>
                    <h3 className="adminSuite-groupTitle">{t("home.admin")}</h3>
                    <div style={{display: "flex", gap:"2rem", flexWrap: "wrap", justifyContent: "center", paddingBottom: "20px"}}>
                        <AdminsuiteItem logo={users} itemName="users" title={t("profile.admin.dashboard.users")} onClick={() => navigate("/profile/admin/users")}/>
                        <AdminsuiteItem logo={groups} itemName="groups" title={t("profile.admin.dashboard.groups")} onClick={() => navigate("/profile/admin/groups")}/>
                        <AdminsuiteItem logo={formsIcon} itemName="forms" title={t("profile.admin.dashboard.forms")} onClick={() => navigate("/profile/admin/forms")}/>
                        <AdminsuiteItem logo={assignment} itemName="assignments" title={t("profile.admin.dashboard.assignments")} onClick={() => navigate("/profile/admin/assignments")}/>
                        <AdminsuiteItem logo={grade} itemName="data" title={t("profile.admin.dashboard.score")} onClick={() => navigate("/profile/admin/data")}/>
                    </div>
                </div>
            );
        }
    }

    return (
        <DefaultLayout>
            <div className='layout-container animation' style={{maxWidth: "60rem"}}>
                <div className='centered__container'>
                    <h1>{ t('home.title') }</h1>
                    {userAdminRole.role_id === 1 && <p>{ t('home.description') }</p>}
                </div>
                {renderHomeInterface()}
            </div>
        </DefaultLayout>
    );
}

export default Home;