import '../Profile.css'
import wachtwoord from "../../../../src/assets/icons/lock.svg";
import ProfileTitle from "../../../components/profile/ProfileTitle";
import { useContext, useState } from "react";
import ProfileLayout from '../../../layouts/ProfileLayout';
import Button from '../../../components/button/Button';
import { useTranslation } from 'react-i18next';
import { updatePassword } from '../../../services/authService';
import { AuthContext } from '../../../contexts/AuthContext';
import { ToastContext } from '../../../contexts/ToastContext';
import toast from 'react-hot-toast';


const Wachtwoord = () => {
    // GENERAL
	const [ inputChanged, setInputChanged ] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const { token } = useContext(AuthContext)
    const { setToastInstance } = useContext(ToastContext);
    // FORMDATA
    const [formData, setFormData] = useState({
        currentPassword: '',
        newPassword: '',
        repeatPassword: '',
    });
    const { t } = useTranslation();
    
    const handleChange = (e) => {
        setInputChanged(true);
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(false);
        setError('');
        if(formData && formData.newPassword === formData.repeatPassword){
            await updatePassword(token, formData).then(() => {
                setInputChanged(false);
                setFormData({ currentPassword: '', newPassword: '', repeatPassword: '' });
                setToastInstance({ message: t("toasts.editpassword"), type: "success" });
                setError('');
            }).catch((error) => {
                setError(error.message);
                setLoading(false);
                setInputChanged(false);
                setToastInstance({ message: error.message, type: "error" });
            });
        } else {
            setLoading(false);
            setError("Wachtwoord komt niet overeen");
            toast.error("Wachtwoord komt niet overeen");
        }
        
    };

    return (
        <ProfileLayout>
            <div className="profileContent">
                <ProfileTitle logo={wachtwoord} title={ t('profile.wachtwoord.title') } subtitle={ t('profile.wachtwoord.description') }  />
                <div className="contentContainer profileSection">
                    <form onSubmit={handleSubmit} className='profile-form'>
                        {error && <p className='error' style={{marginBottom:0}}>{error}</p>}
                        <label className='label'>
                        { t('profile.wachtwoord.cpswrd') + ":" } 
                            <input className='input' type="password" name="currentPassword" value={formData.currentPassword} onChange={handleChange} placeholder={ t('profile.wachtwoord.cpswrd') } />
                        </label>
                        <label className='label'>
                        { t('profile.wachtwoord.npswrd') + ":" }
                            <input className='input' type="password" name="newPassword" value={formData.newPassword} onChange={handleChange} placeholder={ t('profile.wachtwoord.npswrd') } />
                        </label>
                        <label className='label'>
                        { t('profile.wachtwoord.rnpswrd') + ":" }
                            <input className='input' type="password" name="repeatPassword" value={formData.repeatPassword} onChange={handleChange} placeholder={ t('profile.wachtwoord.rnpswrd') } />
                        </label>
                        <Button loading={loading} text={ t('profile.wachtwoord.change') } disabled={!inputChanged || loading} color={"blue"} type="submit"/>
                    </form>
                </div>
            </div>
        </ProfileLayout>
    );
}

export default Wachtwoord;