import React, { useContext } from "react";
import { VragenlijstContext } from "../../../contexts/VragenlijstContext";
import { removeCookie } from "../../../services/localAnsweredQuestionsService";
import { useTranslation } from 'react-i18next';

const VragenlijstViewDrawer = () => {
    const {name, blocks, isCompleted, currentBlock, changeCurrentBlock} = useContext(VragenlijstContext);
    const { t } = useTranslation();

    return (
        <div className='drawer__content'>
            {name && <p className='drawer-vragenlijstNaam'>{name}</p>}
            <p className='drawer-unansweredQuestions'>{t('vragenlijstDrawer.readOnly')}</p>
            <ul className='drawer__blockItems'>
                {blocks.map ((block, index) => {
                    return (
                        <li onClick={() => changeCurrentBlock(index)} className={'drawer__blockItem ' + (block.block_id === currentBlock.block_id && "drawer__blockItem--selected")} key={index}>
                            <h3>{block.name}</h3>
                        </li>
                    )
                })}
            </ul>
        </div>
    );
    }

export default VragenlijstViewDrawer;