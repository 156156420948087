import React, { useContext, useState } from 'react';
import '../Profile.css';
import algemeen from "../../../../src/assets/icons/settings.svg";
import ProfileTitle from "../../../components/profile/ProfileTitle";
import { AuthContext } from '../../../contexts/AuthContext';
import ProfileLayout from '../../../layouts/ProfileLayout';
import Button from '../../../components/button/Button';
import { useTranslation } from 'react-i18next';
import { ToastContext } from '../../../contexts/ToastContext';


const Algemeen = () => {
	// GENERAL
	const { currentUser, updateUserData } = useContext(AuthContext);
	const [error, setError] = useState('');
	const [ inputChanged, setInputChanged ] = useState(false);
	const { t } = useTranslation();
	const { setToastInstance } = useContext(ToastContext);
	// FORMDATA
	const [formData, setFormData] = useState({
		firstname: currentUser.firstname,
		name: currentUser.name,
		email: currentUser.email,
	});

	const handleChange = (e) => {
		setInputChanged(true);
    	const { name, value } = e.target;
    	setFormData((prevData) => ({ ...prevData, [name]: value }));
	};

	const handleSubmit = (e) => {
    	e.preventDefault();
		if(inputChanged){
			updateUserData(formData).then(() => {
				setInputChanged(false);
				setToastInstance({ message: t("toasts.editprofile"), type: "success" });
				setError('');
			}).catch((error) => {
				setError(error.message);
				setToastInstance({ message: error.message, type: "error" });
			});
		}
	};
  
	return (
		<ProfileLayout>
			<div className="profileContent">
				<ProfileTitle logo={algemeen} title={t("profile.Algemeen.title")} subtitle={t("profile.Algemeen.subtitle")}  />
				<div className="contentContainer profileSection">
					{error && <p className="error">{error}</p>}
					
					<form onSubmit={handleSubmit} className='profile-form' noValidate>
						<label className='label'>
						UUID
							<input style={{fontSize:"10px"}} className='input' type="text" name="uuid" disabled value={currentUser.uuid}/>
						</label>
						<label className='label'>
						{ t('profile.Algemeen.firstName') }
							<input className='input' type="text" name="firstname" value={formData.firstname} onChange={handleChange} placeholder="Firstname" />
						</label>
						<label className='label'>
						{ t('profile.Algemeen.lastName') }
							<input className='input' type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Lastname" />
						</label>
						<label className='label'>
						{ t('profile.Algemeen.email') }
							<input className='input' type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Email" />
						</label>
						<Button text={ t('profile.Algemeen.change') } disabled={!inputChanged} color={"blue"} type="submit"/>			
					</form>
				</div>
			</div>
		</ProfileLayout>
	);
}

export default Algemeen;
