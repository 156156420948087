import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./notfound.css";
import { deleteUser } from "firebase/auth";
import { AuthContext } from "../../contexts/AuthContext";
import { useTranslation } from 'react-i18next';



const UserNotFound = () => {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const { t } = useTranslation();

    const { logOut, currentUser } = useContext(AuthContext);

    useEffect(() => {
        if(currentUser){
            navigate('/');
        }
    }, [currentUser]);

    return (
        <>
            <div className="notfound__container">
                <section className="notfound__content">
                    <h1>{ t('userNotFound.part1') }</h1>
                    <p>{ t('userNotFound.part2') }</p>
                    <p>{ t('userNotFound.part3') }</p>
                    <div className="notfound__buttons">
                        <button className="notfound__button" onClick={() => {
                            //deleteUser(firebaseUser).then(() => {
                            //    navigate('/sign-in');
                            //}).catch((error) => {
                            //    if(error.message === "Firebase: Error (auth/requires-recent-login)."){
                            //        setError("U moet opnieuw inloggen om uw account te verwijderen.");
                            //    } else {
                            //        setError(error.message);
                            //</div>    }
                            //})
                        }}>Verwijder account</button>
                        <button className="notfound__button" onClick={async () => await logOut()}>Log uit</button>
                    </div>
                    {error && <p className="error">{error}</p>}
                </section>
            </div>
        </>
    );
}

export default UserNotFound;