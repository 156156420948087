import React, { useContext, useEffect, useState } from "react";
import ProfileLayout from "../../../../../layouts/ProfileLayout";
import List from "../../../../../components/list/List";
import Breadcrumbs from "../../../../../components/breadcrumbs/Breadcrumbs";
import { deleteGroup, getGroupsOfTeacher } from "../../../../../services/groupsService";
import { AuthContext } from "../../../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import "./Groups.css";
import Button from "../../../../../components/button/Button";
import { useTranslation } from 'react-i18next';


const Groups = () => {
    const breadcrumbs = [ {link: "/profile/admin", name: "Admin"}, {link: "/profile/admin/groups", name: "Groups"} ];
    const [groups, setGroups] = useState([]);
    const { token } = useContext(AuthContext)
    const navigate = useNavigate();

    const { t } = useTranslation();

    useEffect(() => {
        fetchGroups();
    }, []);

    const fetchGroups = () => {
        getGroupsOfTeacher(token).then((groups) => {
            setGroups(groups);
        });
    }

    const removeGroup = (groupId) => {
        deleteGroup(groupId, token).then(() => {
            fetchGroups();
        });
    }

    return (
        <ProfileLayout>
            <div className="profileContent">
                <Breadcrumbs breadcrumbs={breadcrumbs}/>
                <div className="contentContainer">
                    <div className="contentContainer-top">
                        <p>{ t('profile.admin.group.desc1') }{groups.length}{ t('profile.admin.group.desc2') }</p>
                        <Button text={ t('profile.admin.group.button') } color="blue" className="button-small" onClick={() => {navigate("/profile/admin/groups/add")}}/>
                    </div>
                    <List data={groups} fields={["name"]} onClick={removeGroup}/>
                </div>
            </div>
        </ProfileLayout>
    );
}

export default Groups;