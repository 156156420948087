import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import DefaultLayout from "../../layouts/DefaultLayout";
import Spinner from "../../components/spinner/Spinner";
import Button from "../../components/button/Button";
import { AuthContext } from "../../contexts/AuthContext";
import { getUserForm } from "../../services/formService";
import { useTranslation } from 'react-i18next';
import './Confirmation.css';

const Confirmation = () => {
    const { assignmentId } = useParams();
    const { token } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);
    const [formData, setFormData] = useState(null);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getUserForm(assignmentId, token);
                setFormData(data);
                if (!data.is_completed) {
                    navigate(`/vragenlijst/${assignmentId}`);
                }
            } catch (error) {
                navigate(`/vragenlijst/${assignmentId}`);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [assignmentId]);

    const hasAverages = () => {
        return formData && formData.blocks[0].questions[0].average >= 0;
    }

    return (
        <DefaultLayout>
            <div className='layout-container animation' style={{maxWidth: "60rem"}}>
                {isLoading ? 
                    <div className="spinner-container">
                        <Spinner color={"var(--blue-color)"} size={80}/>
                    </div> 
                : 
                    <div className='centered__container'>
                        <h1>{t("confirmation.title")}</h1>
                        <p>{t("confirmation.answer")}</p>
                        {!hasAverages() && <p>{t("confirmation.averages")}</p>}
                        <div className="confirmation__options">
                            {hasAverages() ?
                                <Link to={`/vragenlijst/${assignmentId}/gemiddeldes`}>
                                    <Button text={t("confirmation.viewAverages")} color={"blue"}/>
                                </Link> 
                            :
                                <Button text={t("confirmation.viewAverages")} disabled={true} color={"blue"}/>
                            }
                            <Link to={`/vragenlijst/${assignmentId}`}>
                                <Button text={t("confirmation.answers")} />
                            </Link>
                        </div>  
                    </div>
                }
            </div>
        </DefaultLayout>
    );
}

export default Confirmation;