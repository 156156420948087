import React, { useContext, useEffect, useState } from "react";
import environment from '../../../../environment.json';
import { AuthContext } from "../../../../contexts/AuthContext";
import Spinner from "../../../../components/spinner/Spinner";
import { useNavigate } from "react-router-dom";

const API_BASE_URL = process.env.REACT_APP_API_URL;

const Data = () => {
    const [loading, setLoading] = useState(true);
    const { token } = useContext(AuthContext);
    const navigate = useNavigate();
    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(`${API_BASE_URL}/data`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            });
            if(response.ok){
                const data = await response.blob();
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'database_dump.sql');
                document.body.appendChild(link);
                link.click();
                setLoading(false);
                navigate("/profile/admin");
            }
        };
        fetchData();
    }, []);

    return (
        loading &&
            <div style={{height: "100vh", width: "100vw", display: "grid", placeItems:"center", position: "absolute"}}>
                <Spinner color={"var(--blue-color)"} size={80}/>
            </div>
    );
}

export default Data;
