import React, { useState, useContext, useEffect } from 'react';
import './CardFormBlock.css';
import { VragenlijstContext } from '../../contexts/VragenlijstContext';
import { sendUserFormData } from '../../services/formService';
import { AuthContext } from '../../contexts/AuthContext';
import Spinner from '../spinner/Spinner';
import { useNavigate } from 'react-router-dom';
import { removeCookie } from '../../services/localAnsweredQuestionsService';
import VragenlijstBlock from './VragenlijstBlock';
import { useTranslation } from 'react-i18next';
import { ToastContext } from '../../contexts/ToastContext';

const VragenlijstAssignmentBlock = () => {
    const {isCompleted, currentBlock, blocks, changeCurrentBlock, formId, groupId, assignmentId} = useContext(VragenlijstContext);
    const { currentUser, token} = useContext(AuthContext);
    const { setToastInstance } = useContext(ToastContext);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const isCurrentBlockLastBlock = () => {
      return blocks.indexOf(currentBlock) === blocks.length - 1;
    }

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [currentBlock])

    const submitVragenlijstData = () => {
      setIsSubmitting(true);
      const formData = {
        blocks: blocks,
        form_id: formId,
        assignment_id: assignmentId,
        group_id: groupId,
        user_id: currentUser.user_id,
        open: true
      }
      sendUserFormData(formData, token)
        .then((res) => {
          removeCookie();
          navigate(`/confirmation/${assignmentId}`)
        })
        .catch((err) => {
          setError(err.message);
          console.log("test")
          setToastInstance({ message: err.message, type: "error" });
        })
        .finally(() => {
          setIsSubmitting(false);
        })      
    }

    const renderCardBlockFooter = () => {
      const currentBlockIndex = blocks.indexOf(currentBlock);
    
      return (
        <div className='cardBlock__footer'>
          {currentBlockIndex > 0 && (
            <span className='cardBlock__back' onClick={() => changeCurrentBlock(currentBlockIndex - 1)}>
              {"< "} { t('vragenlijstAssignmentBlock.back') }
            </span>
          )}
          {isCurrentBlockLastBlock() ? (
            !isCompleted ? (
              <button className='cardBlock__button button' onClick={() => submitVragenlijstData()}>
                {isSubmitting ? <Spinner size={30} /> :  t('vragenlijstAssignmentBlock.send') }
              </button>
            ) : (
              <button className='cardBlock__button button' onClick={() => navigate(`/confirmation/${assignmentId}`)}>{ t('vragenlijstAssignmentBlock.avg') }</button>
            )
          ) : (
            <button className='cardBlock__button button' onClick={() => changeCurrentBlock(blocks.indexOf(currentBlock) + 1)}>
              { t('vragenlijstAssignmentBlock.next') }
            </button>
          )}
        </div>
      );
    };

    return (
        <VragenlijstBlock 
          name={currentBlock.name} 
          description={currentBlock.description} 
          questions={currentBlock.questions}
          buttons={renderCardBlockFooter()} 
          error={error}
          blockIndex={blocks.indexOf(currentBlock)}
          editable={!isCompleted && !isSubmitting}
        />
    );
}

export default VragenlijstAssignmentBlock;
