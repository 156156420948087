import environment from "../environment.json";

const API_BASE_URL = process.env.REACT_APP_API_URL;

export async function getUserForms(token) {
  try {
    const response = await fetch(`${API_BASE_URL}/user_forms`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.json();
  } catch (error) {
    console.error(error);
    throw new Error("Er was een probleem bij het ophalen van de gegevens.");
  }
}

export async function getUserForm(assignmentId, token) {
  try {
    const response = await fetch(`${API_BASE_URL}/user_forms/${assignmentId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();

    if (response.status === 400){
      if(data.detail === "Provided session does not exist."){
        throw new Error(`Deze vragenlijst met sessie ID ${assignmentId} bestaat niet.`);
      }
      if(data.detail === "User is not subscribed to this assignment"){
        throw new Error("USER-NOT-SUBSCRIBED")
      }
    } else if (response.status === 404) {
      throw new Error("Deze vragenlijst bestaat niet. Controleer de URL van uw vragenlijst");
    } else if (!response.ok) {
      throw new Error("Er was een probleem bij het ophalen van de gegevens.");
    }
    return data;
  } catch (error) {
    throw error;
  }
}

export async function getForm(formId, token) {
  try {
    const response = await fetch(`${API_BASE_URL}/forms/${formId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 404) {
      throw new Error("Deze vragenlijst bestaat niet. Controleer de URL van uw vragenlijst");
    } else if (!response.ok) {
      throw new Error("Er is een probleem opgetreden bij het ophalen van de gegevens.");
    }

    return response.json();
  } catch (error) {
    throw error;
  }
}

export async function sendUserFormData(formData, token) {
  try {
    const response = await fetch(`${API_BASE_URL}/user_forms`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(formData),
    });

    if (response.status === 422) {
      throw new Error("Gelieve alle vragen in te vullen");
    } else if (!response.ok) {
      throw new Error("Er was een probleem bij het verzenden van de gegevens.");
    }

    return response.json();
  } catch (error) {
    throw error;
  }
}


export async function getFormsOfGroups(assignmentId, token) {
  try {
    const response = await fetch(`${API_BASE_URL}/group_forms/${assignmentId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("db-forms-error");
    }
    return response.json();
  } catch (error) {
    throw new Error("Er was een probleem bij het ophalen van de gegevens.");
  }
}

export async function postForm(formData, token) {
  try {
    const response = await fetch(`${API_BASE_URL}/forms?teacher_id=${token}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(formData),
    });

    if (response.status === 422) {
      throw new Error("Gelieve alle vragen in te vullen");
    } else if (!response.ok) {
      throw new Error("Er was een probleem bij het verzenden van de gegevens.");
    }

    return response.json();
  } catch (error) {
    throw error;
  }
}

export async function deleteForm(formId, token) {
  try {
    const response = await fetch(`${API_BASE_URL}/forms?form_id=${formId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if(response.status === 405){
      throw new Error("Deze vragenlijst kan niet verwijderd worden omdat er al assignments van gemaakt zijn.");
    }

    if (!response.ok) {
      throw new Error("Er was een probleem bij het verwijderen van de vragenlijst.");
    }
  } catch (error) {
    throw error;
  }
}

export async function getForms(token) {
  try {
    const response = await fetch(`${API_BASE_URL}/forms`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.json();
  } catch (error) {
    throw new Error("Er was een probleem bij het ophalen van de gegevens.");
  }
}
