import React, { createContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Spinner from '../components/spinner/Spinner';
import { updateDatabaseUser } from '../services/userService';
import { getUser, signInUser, signUpUser } from '../services/authService';

export const AuthContext = createContext();

export const AuthProvider = ({children}) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [token, setToken] = useState(null);
    const [userAdminRole, setUserAdminRole] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();

    const updateUserData = async (formData) => {
      try {
        const requestUser = {
          firstname: formData.firstname || currentUser.firstname,
          name: formData.name || currentUser.name,
          email: formData.email || currentUser.email,
          sex: (formData.sex || currentUser.sex).toLowerCase(),
          language: formData.language || currentUser.language,
          birthdate: formData.birthdate || currentUser.birthdate,
          marital_state: formData.marital_state || currentUser.marital_state,
          education: formData.education || currentUser.education,
          permission_id: formData.permission_id || currentUser.gdpr_permission.permission_id,
        }
  
        await updateDatabaseUser(requestUser, token)
        .then(() => {
          setCurrentUser((prevData) => ({ ...prevData, ...formData }))
        });
      } catch (error) {
        throw error;
      }
    }

    const signIn = async (email, password) => {
      try {
        const data = await signInUser(email, password);
        localStorage.setItem('auth_token', data.access_token);
        navigate('/');
      } catch (error) {
        throw error;
      }
    };

    const signOut = () => {
      localStorage.removeItem('auth_token');
      setCurrentUser(null);
      setUserAdminRole(false);
      navigate('/sign-in');
    };

    const signUp = async (userData) => {
      try {
        const data = await signUpUser(userData);
        navigate('/');
      }
      catch (error) {
        throw error;
      } 
    }

    useEffect(() => {
      const handleAuth = async () => {
        if(currentUser) return;

        setLoading(true);

        // get token from local storage
        const token = localStorage.getItem('auth_token');

        if(!token) {
          if(location.pathname !== "/sign-in" && location.pathname !== "/sign-up") navigate('/sign-in');
          setLoading(false);
          return;
        }

        getUser(token).then((user) => {
          // Check if user is logged in
          if (!user) {
            // If user is not logged in, navigate to sign-in page
            localStorage.removeItem('auth_token');
            navigate('/sign-in');
          } else {
            // If user is logged in, proceed with your logic
            if(location.pathname === "/sign-in" || location.pathname === "/sign-up") navigate('/');
            setCurrentUser(user);
            if(user.role?.role_id === 5 || user.role?.role_id === 4 || user.role?.role_id === 3 || user.role?.role_id === 2) setUserAdminRole(user.role);
            setToken(token);
          }
          setLoading(false);
        }).catch((err) => {
          setError(err.message);
          setLoading(false);
        });
      };
  
      handleAuth();
    }, [location.pathname]);

    const authInfo = {currentUser, userAdminRole, token, signIn, signOut, signUp, updateUserData}

    return (
        <AuthContext.Provider value={authInfo}>
          {loading ? <div style={{height: "100vh", width: "100vw", display: "grid", placeItems:"center", position: "absolute"}}>
            <Spinner color={"var(--blue-color)"} size={80}/>
          </div> : (!error ? children : <> <div className='error'>Error: {error.message}</div><button onClick={() => {signOut();window.location.reload()}} className='button'>Log uit & Reset</button></>)}
        </AuthContext.Provider>
    );
};
