import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import Spinner from "../../components/spinner/Spinner";
import DefaultLayout from "../../layouts/DefaultLayout";
import FormLayout from "../../layouts/FormLayout";
import { VragenlijstContext } from "../../contexts/VragenlijstContext";
import { getForm } from "../../services/formService";
import VragenlijstViewDrawer from "../../components/drawer/drawercontents/VragenlijstViewDrawer";
import VragenlijstViewBlock from "../../components/formBlocks/VragenlijstViewBlock";
import { useTranslation } from 'react-i18next';

const VragenlijstView = () => {
    const { formId } = useParams();
    const { token } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const {setGeneralFormData} = useContext(VragenlijstContext);
    const { t } = useTranslation();

    useEffect(() => {
        fetchFormData();
    }, [formId]);

    const fetchFormData = async () => {
        setIsLoading(true);
        try {
            const data = await getForm(formId, token);
            await setGeneralFormData(data);
            setIsLoading(false);
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    }

    return (
    <>
        {isLoading 
        ? 
            <div className="spinner-container"><Spinner color={"var(--blue-color)"} size={80}/></div> 
        : 
            error
                ?
                    <DefaultLayout>
                        <div className="error-container">
                            <div className="error-container__wrapper">
                                <h1>{t('errors.unknown')}</h1>
                                <p className="error">{error}</p>
                            </div>
                        </div>
                    </DefaultLayout>
                :
                    <FormLayout drawerChildren={<VragenlijstViewDrawer/>}><VragenlijstViewBlock/></FormLayout>
        }
    </>
    );
}

export default VragenlijstView;