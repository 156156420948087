import React from "react";
import './FormCard.css'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const FormCard = ({form}) => {
    const { t } = useTranslation();

    return (
        <div id={form.form_id} key={1} className="card">
            <h2>{form.name}</h2>
            <hr className="card-divider"></hr>
            <p> {form.description} </p> 
            <Link to={form.is_completed ? `/confirmation/${form.assignment_id}` : `/vragenlijst/${form.assignment_id}`}>
                <button className="navButton button">{form.is_completed ?  t('formCard.look')  : t('formCard.fillin')}</button>
            </Link>
        </div>
        
    );
};

export default FormCard