import React, { useContext, useEffect, useState, useRef } from "react";
import "./Gemiddeldes.css";
import DefaultLayout from "../../../layouts/DefaultLayout";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";
import Spinner from "../../../components/spinner/Spinner";
import { getUserForm } from "../../../services/formService";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import Chart from "chart.js/auto";
import { useTranslation } from 'react-i18next';

const Gemiddeldes = () => {
    const { assignmentId } = useParams();
    const { token } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState(null);
    const navigate = useNavigate();
    const chartRef = useRef(null);
    const { t } = useTranslation();

    useEffect(() => {
        getUserForm(assignmentId, token)
            .then((data) => {
                setFormData(data);
                if (!data.is_completed) {
                    navigate(`/vragenlijst/${assignmentId}`);
                }
            })
            .catch((error) => {
                setError(error.message);
            }).finally(() => {
                setIsLoading(false);
            })
    }, [assignmentId]);

    useEffect(() => {
        if (formData) {
            renderChart();
        }
    }, [formData]);

    const renderChart = () => {
        const labels = formData.blocks.map(block => block.name);
        const averages = formData.blocks.map(block => calculateBlockAverage(block));

        const ctx = chartRef.current.getContext('2d');

        new Chart(ctx, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: [{
                    label: 'Gemiddelde Score',
                    data: averages,
                    backgroundColor: 'rgba(54, 162, 235, 0.5)',
                    borderColor: 'rgba(54, 162, 235, 1)',
                    borderWidth: 1
                }]
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true,
                        suggestedMax: 5,
                        max: 5,
                    }
                },
            }
        });
    };

    const calculateBlockAverage = (block) => {
        let totalAverage = 0;
        const questions = block.questions;

        if (questions.length === 0) {
            return totalAverage;
        }

        questions.forEach(question => {
            totalAverage += question.average;
        });

        return (totalAverage / questions.length).toFixed(2);
    };

    return (
        <DefaultLayout>
            <div className='layout-container animation' style={{ maxWidth: "60rem" }}>
                {isLoading ?
                    <div className="spinner-container">
                        <Spinner color={"var(--blue-color)"} size={80} />
                    </div>
                    :
                    <>
                        {formData && formData.blocks.length > 0 ?
                            <>
                                <Breadcrumbs breadcrumbs={[{ link: `/confirmation/${assignmentId}`, name: "Ga terug" }]} />
                                <div className='centered__container'>
                                    <div>
                                        <h1>{t("gemiddeldes.from")} {formData.name}</h1>
                                        <p>{t("gemiddeldes.session")} {formData.assignment_id}</p>
                                    </div>
                                </div>
                                {formData.blocks.map((block, index) => {
                                    return (
                                        <div key={index} className="centered__container gemiddeldes__container">
                                            <div className="gemiddeldes__block">
                                                <h3>{t("gemiddeldes.fromBlock")} {block.name}: <span className="score">{calculateBlockAverage(block)}</span></h3>
                                                <div className="block__description">
                                                    {block.description && <p>{block.description}</p>}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                <div className="centered__container gemiddeldes__graph">
                                    <canvas ref={chartRef}></canvas>
                                </div>
                                <div className="centered__container">
                                    <h2>{t("gemiddeldes.avgQuestion")}</h2>
                                    {formData.blocks.map((block, index) => {
                                        return (
                                            <table className="gemiddeldes__table">
                                                <thead>
                                                    <tr>
                                                        <th>{block.name}</th>
                                                        <th>{t("gemiddeldes.avg")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {block.questions.map((question, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{question.question}</td>
                                                                <td style={{width: 100}}>{question.average}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        )
                                    })}
                                </div>
                            </>
                            :
                            <div className="centered__container">
                                <p>{t("gemiddeldes.notAvailable")}</p>
                            </div>
                        }
                    </>

                }

            </div>
        </DefaultLayout>
    );
}

export default Gemiddeldes;