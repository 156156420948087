import React, { useContext, useEffect, useState } from "react";
import "./FormCard.css";
import { Link } from "react-router-dom";
import trash from "../../assets/icons/delete.svg";
import eye from "../../assets/icons/eye.svg";
import open from "../../assets/icons/lockOpen.svg";
import closed from "../../assets/icons/lockClosed.svg";
import "./FormListCard.css";

const FormListCard = ({ form, deleteForm }) => {
  const [locked, setLocked] = useState(false);

  const handleClickDeleteForm = (e) => {
    e.preventDefault();
    deleteForm(form.form_id);
  };

  return (
    <div className={`formCardContainer ${locked ? "lockedCard" : ""}`}>
      <div className="formCardHead">
        <p className="title">{form.name}</p>
        <p className="description">{form.description}</p>
      {console.log(form.color)}
        <div className="colorPicker formlistcard" style={{backgroundColor: `#${form.color}`}}></div>
      </div>

      <div className="formCardActions">
        <p className="status">complete</p>

        <div className="square">
          <Link to={`/vragenlijst/view/${form.form_id}`} className="FormCardIconContainer">
            <img src={eye} width="100%" height="100%" />
          </Link>
          <div className="line"></div>
          <button className="FormCardIconContainer" onClick={(e) => handleClickDeleteForm(e)}>
            <img src={trash} width="100%" height="100%" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormListCard;
