import React from "react";
import "./AdminsuiteItem.css";
import { Link } from "react-router-dom";

const AdminsuiteItem = ({ logo, title, itemName, style }) => {
    return (
        <Link to={`/profile/admin/${itemName}`} style={style}>
            <div className="adminSuite-item" style={style}>
                <img src={logo} alt={title} style={{ height: "35px" }} />
                <div className="adminsuiteItem-divider"></div>
                <h3 className="adminSuite-item-title">{title}</h3>
                <div className="adminSuite-item-arrow"></div>
            </div>
        </Link>
    );
};

export default AdminsuiteItem;