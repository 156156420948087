import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { getSchools } from '../../services/schoolService';
import Button from '../../components/button/Button';
import { useTranslation } from 'react-i18next';
import { ToastContext } from '../../contexts/ToastContext';

const Signup = ({ toggleComponent }) => {
  // GENERAL
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { signUp } = useContext(AuthContext);
  const { setToastInstance } = useContext(ToastContext);
  const [error, setError] = useState('');
  // DATA
  const [schools, setSchools] = useState([]);
  // FORMDATA
  const [name, setName] = useState('');
  const [gender, setGender] = useState('M');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [school, setSchool] = useState('');
  const [schoolId, setSchoolId] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const schools = await getSchools();
        setSchools(schools);
        if (schools.length > 0) {
          setSchool(schools[0].id);
        }
      } catch (error) {
        setError(t("error.schools"));
      }
    };
    fetchSchools();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const userData = {
      email,
      firstname: name.split(" ")[0],
      name: name.split(" ").slice(1).join(" "),
      password,
      school_id: school,
      school_user_id: schoolId,
      sex: gender,
      role_id: 1,
      permission_id: 1,
    };

    try {
      await signUp(userData);
      setToastInstance({ message: t("toasts.register"), type: "success" });
      setError('');
      navigate('/sign-in');
    } catch (error) {
      setError(error.message);
    }

    setLoading(false);
  };

  return (
    <div className="form-container sign-up-container">
      <form className='form-auth' noValidate onSubmit={handleSubmit}>
        <h1 className='form-title'>{t("signup.register")}</h1>
        <div className='firstRow'>
          <input
            type="text"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder={t("signup.name")}
          />
          <select
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            name="Geslacht"
          >
            <option value="M">M</option>
            <option value="V">V</option>
            <option value="X">X</option>
          </select>
        </div>

        <input
          type="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
        />
        <div className='thirdRow'>
          <select
            value={school}
            onChange={(e) => setSchool(e.target.value)}
            name="School"
          >
            {schools.map((school) => (
              <option key={school.id} value={school.id}>
                {school.name}
              </option>
            ))}
          </select>
          <input
            type="text"
            name="schoolId"
            value={schoolId}
            onChange={(e) => setSchoolId(e.target.value)}
            placeholder={t("signup.schoolID")}
          />
        </div>
        <input
          type="password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder={t("signup.password")}
        />
        <div className='error'>
          {error && <span>{error}</span>}
        </div>
        <Button text={t("signup.register")} type="submit" loading={loading} className="form-auth-button" />
        <div className="mobile-toggleButtons">
          <a className='link' onClick={() => toggleComponent("/sign-in")}>{t("signup.login")}</a>
        </div>
      </form>
    </div>
  );
}

export default Signup;