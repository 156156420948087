import React, { createContext, useState } from 'react';
import { getAmountOfAnsweredQuestions, getAnswerForQuestionFromCookie, initCookie } from '../services/localAnsweredQuestionsService';

export const VragenlijstContext = createContext();

/** 
    VragenlijstProvider is een component die je kan gebruiken om data globaal over het hele project te gebruiken.
    - Alle useStates en functies die je hier definieert zijn beschikbaar in alle componenten die de VragenlijstContext importeren.
    - Zo kun je iets aanpassen in de ene component en wordt het automatisch aangepast in de andere components.
*/
export const VragenlijstProvider = ({ children }) => {
    const [isCompleted, setIsCompleted] = useState(false);
    const [blocks, setBlocks] = useState([]);
    const [currentBlock, setCurrentBlock] = useState({});
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [color, setColor] = useState('');
    const [formId, setFormId] = useState(0);
    const [groupId, setGroupId] = useState(0);
    const [assignmentId, setAssignmentId] = useState(0);

    /** Deze functie wordt **enkel** gecalled in Vragenlijst.jsx
        - Het zet de data van de API om in de juiste vorm en slaat het op hier in de globale states
    */
    const setFormDataForAssignment = async (data) => {
        setAssignmentId(data.assignment_id);
        await initCookie(data.assignment_id);
        if(!data.is_completed){
            data.blocks = await getAnswersFromCookie(data.blocks);
        }
        setIsCompleted(data.is_completed);
        setGroupId(data.group?.group_id);
        setGeneralFormData(data);
    }

    const setGeneralFormData = (data) => {
        setIsCompleted(data.is_completed);
        setBlocks(data.blocks);
        setCurrentBlock(data.blocks[0]);
        setName(data.name);
        setDescription(data.description);
        setColor(data.color);
        setFormId(data.form_id);
        setGroupId(data.group?.group_id);
    }

    const getAnswersFromCookie = async (blocks) => {
        let newBlocks = [...blocks];
        for (let i = 0; i < newBlocks.length; i++) {
            for (let j = 0; j < newBlocks[i].questions.length; j++) {
                newBlocks[i].questions[j].answer = await getAnswerForQuestionFromCookie(i, newBlocks[i].questions[j].question_id);
            }
        }
        return newBlocks;
    };

    const changeCurrentBlock = (index) => {
        setCurrentBlock(blocks[index]);
    }

    const getAnswerForQuestion = (blockIndex, questionIndex) => {
        const question = blocks[blockIndex].questions[questionIndex];
        if(question.answer > 0) return question.answer;
        else return getAnswerForQuestionFromCookie(blockIndex, question.question_id);
    }

    const updateQuestionAnswer = (blockIndex, questionIndex, answer) => {
        let newBlocks = [...blocks];
        newBlocks[blockIndex].questions[questionIndex].answer = answer;
        setBlocks(newBlocks);
    }

    const getUnansweredQuestions = () => {
        // get amount of questions of blocks
        const totalAmountOfQuestions = blocks.reduce((acc, block) => {
            return acc + block.questions.length;
        }, 0);
        // get amount of answered questions from localStorage
        const answeredQuestions = getAmountOfAnsweredQuestions();
        // return the amount of unanswered questions
        return totalAmountOfQuestions - answeredQuestions;        
    }
  
    // alle useStates en functies die je buiten deze component wilt gebruiken (die dus beschikbaar zijn),
    // moeten hieronder in de value statement worden gezet.
    return (
      	<VragenlijstContext.Provider
			value={{
				isCompleted,
				blocks,
				name,
				description,
				color,
				currentBlock,
                setGeneralFormData,
				setFormDataForAssignment,
				changeCurrentBlock,
				updateQuestionAnswer,
                getAnswerForQuestion,
                getUnansweredQuestions,
				formId,
				groupId,
				assignmentId
			}}
      	>
        	{children}
      	</VragenlijstContext.Provider>
    );
  };