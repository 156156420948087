import React from 'react';
import Drawer from '../components/drawer/Drawer';
import Header from '../components/header/Header';

/*
    Layout for the form pages
    within the wrapper:
    - Drawer
    - container
        - Header
        - children in main
*/
const FormLayout = ({ children, drawerChildren }) => {
    return (
        <div className='layout-wrapper'>
            <Drawer>{drawerChildren}</Drawer>
            <div className='container'>
                <Header />
                <main className='main'>{children}</main>
            </div>
        </div>
    );
};

export default FormLayout;
