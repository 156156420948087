import environment from '../environment.json';

const API_BASE_URL = process.env.REACT_APP_API_URL;

export async function getSchools() {
    try {
        console.log(process.env);
        console.log(process.env.REACT_APP_API_URL);
        console.log(`${API_BASE_URL}/schools`);
        const response = await fetch(`${API_BASE_URL}/schools`, {
            method: 'GET',
        });

        if(!response.ok){
            throw new Error('db-schools-error');
        }

        return response.json();
    } catch (error) {
        throw error;
    }
}
