import React from 'react';
import Header from '../components/header/Header';

/*
    Layout for the dashboard pages
    within the wrapper:
    - container
        - Header
        - children in main
*/
const DefaultLayout = ({ children }) => {
    return (
        <div className='layout-wrapper-no-sidebar'>
            <div className='container'>
                <Header />
                <main className='main'>{children}</main>
            </div>
        </div>
    );
};



export default DefaultLayout;