import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../../../../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { getFormsOfGroups } from "../../../../../../services/formService";
import { Chart } from "chart.js";
import Breadcrumbs from "../../../../../../components/breadcrumbs/Breadcrumbs";
import Spinner from "../../../../../../components/spinner/Spinner";
import DefaultLayout from "../../../../../../layouts/DefaultLayout";

const AssignmentGemiddeldes = () => {
    const { assignmentId } = useParams();
    const { token } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState(null);
    const [group, setGroup] = useState(null);
    const [feedbacks, setFeedbacks] = useState(null);
    const navigate = useNavigate();
    const chartRef = useRef(null);
    const { t } = useTranslation();

    useEffect(() => {
        getFormsOfGroups(assignmentId, token)
            .then((data) => {
                setFormData(data);
            })
            .catch((error) => {
                setError(error.message);
            }).finally(() => {
                setIsLoading(false);
            })
    }, [assignmentId]);

    useEffect(() => {
        if (group) {
            renderChart();
            const feedbacks = formData.blocks.map(block => block.feedback.find(_group => _group.group_id === group.group_id).feedback);
            setFeedbacks(feedbacks);
        }
    }, [group]);

    const renderChart = () => {
        const labels = formData.blocks.map(block => block.name);
        const averages = formData.blocks.map(block => calculateBlockAverage(block));
    
        const ctx = chartRef.current.getContext('2d');
    
        // Destroy previous chart instance if it exists
        if (chartRef.current.chart) {
            chartRef.current.chart.destroy();
        }
    
        chartRef.current.chart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: [{
                    label: 'Gemiddelde Score',
                    data: averages,
                    backgroundColor: 'rgba(54, 162, 235, 0.5)',
                    borderColor: 'rgba(54, 162, 235, 1)',
                    borderWidth: 1
                }]
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true,
                        suggestedMax: 5,
                        max: 5,
                    }
                },
            }
        });
    };
    
    const calculateBlockAverage = (block) => {
        let totalAverage = 0;
        const questions = block.questions;

        if (questions.length === 0) {
            return totalAverage;
        }

        questions.forEach(question => {
            const averageForGroup = question.averages.find(average => average.group_id === group.group_id);
            if (averageForGroup) {
                totalAverage += averageForGroup.average;
            }
        });

        const averageForGroup = totalAverage / questions.length;

        return averageForGroup;
    };

    return (
        <DefaultLayout>
            <div className='layout-container animation' style={{ maxWidth: "60rem" }}>
                {isLoading ?
                    <div className="spinner-container">
                        <Spinner color={"var(--blue-color)"} size={80} />
                    </div>
                    :
                    <>
                        <Breadcrumbs breadcrumbs={[{ link: `/profile/admin/assignments`, name: "Assignments" }]} />
                        <div className='centered__container'>
                            <div>
                                <h1>{t("gemiddeldes.from")} {formData.name}</h1>
                                <p>{t("gemiddeldes.session")} {formData.assignment_id}</p>
                            </div>
                        </div>
                        <div className="centered__container gemiddeldes__container">
                            <h2>Groepen van deze assignment</h2>
                            <ul>
                                {formData.groups.map((group, index) => {
                                    return (
                                        <li onClick={() => setGroup(group)} key={index}>{group.name}</li>
                                    )}
                                )}
                            </ul>
                        </div>
                        {(group && formData.blocks.length > 0) ?
                        <>
                            <div className="centered__container gemiddeldes__container">
                                <div className="gemiddeldes__block">
                                    <h3>{group.name}</h3>
                                    <div className="centered__container gemiddeldes__graph">
                                        <canvas ref={chartRef}></canvas>
                                    </div>
                                </div>
                            </div>
                            <div className="centered__container">
                            <h2>{t("gemiddeldes.avgQuestion")}</h2>
                            {formData.blocks.map((block, blockIndex) => {
                                return (
                                    <table className="gemiddeldes__table">
                                        <thead>
                                            <tr>
                                                <th>{block.name}</th>
                                                <th>{t("gemiddeldes.avg")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {block.questions.map((question, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{question.question}</td>
                                                        <td style={{ width: 100 }}>{question.averages.find((_group) => _group.group_id === group.group_id).average}</td>
                                                    </tr>
                                                )
                                            })}
                                            <tr>
                                                <td colSpan="2" className="feedback" style={{ border: 'none' }}>
                                                    {feedbacks && feedbacks[blockIndex] && (
                                                        <>
                                                            <p>{feedbacks[blockIndex].main_feedback}</p>
                                                            {feedbacks[blockIndex].feedback_steps.map((step, index) => (
                                                                <p key={index}>{++index}) {step}</p>
                                                            ))}
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )
                            })}
                            </div>
                            </>
                            :
                            <div className="centered__container">
                                <p>{t("gemiddeldes.selectGroup")}</p>
                            </div>
                        }
                    </>

                }

            </div>
        </DefaultLayout>
    );
}

export default AssignmentGemiddeldes;