import React, { useContext, useEffect, useState } from "react";
import "./Assignments.css";
import ProfileLayout from "../../../../../layouts/ProfileLayout";
import Breadcrumbs from "../../../../../components/breadcrumbs/Breadcrumbs";
import { AuthContext } from "../../../../../contexts/AuthContext";
import { getAssignments } from "../../../../../services/assignmentService";
import Spinner from "../../../../../components/spinner/Spinner";
import Button from "../../../../../components/button/Button";
import { useNavigate } from "react-router-dom";
import SearchFilter from "../../../../../components/search-filter/SearchFilter";
import AssignmentCard from "../../../../../components/assignmentCard/AssignmentCard";
import { useTranslation } from 'react-i18next';


const Assignments = () => {
    // GENERAL
    const breadcrumbs = [ {link: "/profile/admin", name: "Admin"}, {link: "/profile/admin/assignments", name: "Assignments"} ];
    const { token } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    // DATA
    const [assignments, setAssignments] = useState([]);
    // FILTERED DATA
    const [filteredAssignments, setFilteredAssignments] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [sortingOption, setSortingOption] = useState("A-Z");

    const { t } = useTranslation();


    useEffect(() => {
        fetchAssignments();
    } , []);

    const fetchAssignments = () => {
        setLoading(true);
        getAssignments(token).then((data) => {
            setAssignments(data);
            setFilteredAssignments(data);
            setError(null);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setLoading(false);
        });
    }

    const filterAndSortAssignments = () => {
        let filteredAssignments = assignments;
        
        if (searchValue !== "") {
            filteredAssignments = filteredAssignments.filter((assignment) =>
            assignment.name.toLowerCase().includes(searchValue.toLowerCase())
          );
        }
    
        // TODO: uncomment when status field is implemented in api
        /*
        if (formStatus !== "All") {
          filteredForms = filteredForms.filter(form => form.status === formStatus);
        }
        */
    
        if (sortingOption === "A-Z") {
            filteredAssignments = filteredAssignments.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
        } else if (sortingOption === "Z-A") {
            filteredAssignments = filteredAssignments.sort((a, b) =>
            b.name.localeCompare(a.name)
          );
        }
    
        // Update state with filtered and sorted forms
        setFilteredAssignments(filteredAssignments);
      };

    useEffect(() => {
    filterAndSortAssignments(assignments);
    }, [searchValue, sortingOption]);

    return (
        <ProfileLayout>
            <div className="profileContent">
                <Breadcrumbs breadcrumbs={breadcrumbs}/>
                <div className="contentContainer adminSuite-flex">
                    {error && <p className="error">{error}</p>}
                    <div className="contentContainer-top">
                        <p className="top-text">{ t('profile.admin.assignments.desc') }</p>
                        <Button text={ t('profile.admin.assignments.button') } color="blue" className="button-small" onClick={() => {navigate("/profile/admin/assignments/add")}}/>
                    </div>
                    {loading ? (
                        <div className="contentContainer-loading">
                            <Spinner color={"var(--blue-color)"} size={80}/>
                        </div>
                    ) : (
                        <>
                            <SearchFilter
                                searchValue={searchValue}
                                setSearchValue={setSearchValue}
                                sortingOption={sortingOption}
                                setSortingOption={setSortingOption}
                                reload={fetchAssignments}
                                navigateToAdd={() => navigate("/profile/admin/assignments/add")}
                            />
                            <div className="assignment-items">
                            {filteredAssignments.length === 0 && <p className="error">{ t('profile.admin.assignments.noAssignments') }</p>}
                            {filteredAssignments.map((assignment) => (
                                <AssignmentCard key={assignment.assignment_id} assignment={assignment} setError={setError} fetchAssignments={fetchAssignments}/>
                            ))}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </ProfileLayout>
    );
}

export default Assignments;