import React, { useState, useRef, useCallback, useEffect, useContext } from 'react';
import './Drawer.css';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

const Drawer = ({children}) => {
    const [isOpen, setIsOpen] = useState(true);
    const sidebarRef = useRef(null);
    const [isResizing, setIsResizing] = useState(false);
    const [sidebarWidth, setSidebarWidth] = useState(268);

    function toggleDrawer() {
        setIsOpen(!isOpen);
        setSidebarWidth(isOpen ?  32 : 268);
    }

    const startResizing = useCallback((mouseDownEvent) => {
        setIsResizing(true);
    }, []);

    const stopResizing = useCallback(() => {
        setIsResizing(false);
    }, []);

    const resize = useCallback(
        (mouseMoveEvent) => {
          if (isResizing) {
            setSidebarWidth(
              mouseMoveEvent.clientX - sidebarRef.current.getBoundingClientRect().left
            );
          }
        },
        [isResizing]
    );

    useEffect(() => {
        window.addEventListener("mousemove", resize);
        window.addEventListener("mouseup", stopResizing);
        return () => {
          window.removeEventListener("mousemove", resize);
          window.removeEventListener("mouseup", stopResizing);
        };
      }, [resize, stopResizing]);

      return (
        <div
            className={`drawer${isOpen ? " drawer-minWidth" : ""}`}
            ref={sidebarRef}
            onMouseDown={(e) => e.preventDefault()}
            style={{ width: sidebarWidth }}
        >
            {isOpen && children}
    
            <div className="drawer__side">
                {isOpen ? (
                    <KeyboardDoubleArrowLeftIcon
                        style={{ cursor: "pointer" }}
                        fontSize="large"
                        onClick={toggleDrawer}
                    />
                ) : (
                    <KeyboardDoubleArrowRightIcon
                        style={{ cursor: "pointer" }}
                        fontSize="large"
                        onClick={toggleDrawer}
                    />
                )}
            </div>
    
            {isOpen && <div className="drawer-resizer" onMouseDown={startResizing} />}
        </div>
    );
    
}

export default Drawer;