import environment from '../environment.json';

const API_BASE_URL = process.env.REACT_APP_API_URL;

export async function getGroupsOfTeacher(token) {
    try {
        const response = await fetch(`${API_BASE_URL}/groups`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if(!response.ok){
            throw new Error('db-groups-error');
        }

        return response.json();
    } catch (error) {
        throw error;
    }
}

export async function postGroup(request, token) {
    try {
        const response = await fetch(`${API_BASE_URL}/groups`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(request)
        });

        if(!response.ok){
            throw new Error('db-groups-error');
        }

        return response.json();
    } catch (error) {
        throw error;
    }
}

export async function deleteGroup(groupId, token) {
    try {
        const response = await fetch(`${API_BASE_URL}/groups?group_id=${groupId}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if(!response.ok){
            throw new Error('db-groups-error');
        }
    } catch (error) {
        throw error;
    }
}
