import React from "react";
import Spinner from "../../components/spinner/Spinner";
import DefaultLayout from "../../layouts/DefaultLayout";
import "./Vragenlijst.css";
import { useTranslation } from 'react-i18next';

const Vragenlijst = ({children, error, isLoading}) => {
    const { t } = useTranslation();

    return (
    <>
        {isLoading 
        ? 
            <div className="spinner-container"><Spinner color={"var(--blue-color)"} size={80}/></div> 
        : 
            error
                ?
                    <DefaultLayout>
                        <div className="error-container">
                            <div className="error-container__wrapper">
                                <h1>{t("errors.unknown")}</h1>
                                <p className="error">{error}</p>
                            </div>
                        </div>
                    </DefaultLayout>
                :
                    children
        }
    </>
    )
}

export default Vragenlijst;