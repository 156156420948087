import React, { useContext, useEffect, useState } from "react";
import DefaultLayout from "../../../layouts/DefaultLayout";
import Button from "../../../components/button/Button";
import { getAssignmentDetails, subscribe } from "../../../services/assignmentService";
import { AuthContext } from "../../../contexts/AuthContext";
import Spinner from "../../../components/spinner/Spinner";
import { useTranslation } from 'react-i18next';


const SubscribePage = ({assignmentId, setNotSubscribed}) => {
    const [ error, setError ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(true);
    const [ assignmentDetails, setAssignmentDetails ] = useState({});
    const { token } = useContext(AuthContext);
    const { t } = useTranslation();

    useEffect(() => {
        fetchAssignmentDetails();
    }, []);

    const fetchAssignmentDetails = async () => {
        getAssignmentDetails(assignmentId, token).then((data) => {
            setAssignmentDetails(data);
        }).catch((error) => {
            setError(error.message);
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const subscribeUser = (groupId) => {
        setIsLoading(true);
        subscribe(assignmentId, groupId, token).then(() => {
            setNotSubscribed(false);
        }).catch((error) => {
            setError(error.message);
        }).finally(setIsLoading(false));
    }

    return (
        <DefaultLayout>
                <div className='layout-container animation' style={{maxWidth: "60rem"}}>
                    <div className='centered__container'>
                        <h1>{t('profile.subscribe.title')}</h1>
                        {assignmentDetails.open ? 
                        <>
                            <p>{t('profile.subscribe.desc1')} {assignmentDetails.form_name} {t('profile.subscribe.desc2')}</p>
                            {error && <p className="error">{error}</p>}
                            <div className="groups-container">
                                {assignmentDetails.groups && assignmentDetails.groups.length === 0 && <p className="error">{t('profile.subscribe.noGroup')}</p>}
                                {assignmentDetails.groups && assignmentDetails.groups.map((group) => (
                                    <div key={group.group_id} className="group-item">
                                        <h3>{group.name}</h3>
                                        <Button id={group.group_id} text={t('profile.subscribe.chGroup')} color="red" className="button-small" onClick={(e) => subscribeUser(e.target.id)}/>
                                    </div>
                                ))}
                            </div>
                        </> :
                        <p>{t('profile.subscribe.closed')}</p>}
                    </div>
                </div>
                {isLoading && <div className="spinner-container"><Spinner color={"var(--blue-color)"} size={80}/></div> }
           </DefaultLayout>
    );
}

export default SubscribePage;