import React from "react";
import '../../../Profile.css';
import ProfileLayout from "../../../../../layouts/ProfileLayout";
import Breadcrumbs from "../../../../../components/breadcrumbs/Breadcrumbs";
import ListForms from "./ListForms";

const Forms = () => {
    return (
        <ProfileLayout>
            <div className="profileContent">
                <Breadcrumbs breadcrumbs={[ {link: "/profile/admin", name: "Admin"}, {link: "/profile/admin/forms", name: "Forms"} ]}/>
                <div className="contentContainer">
                    <ListForms />
                </div>
            </div>
        </ProfileLayout>
    );
}

export default Forms;