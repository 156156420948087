import i18n from 'i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';

i18n
  .use(ChainedBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'nl',
    debug: true,

    backend: {
      backends: [LocalStorageBackend, HttpBackend],
      backendOptions: [],
      cacheHitMode: 'refreshAndUpdateStore',
    },

    react: {
      bindI18nStore: 'added', 
    },
  });

export default i18n;