import React, { useEffect, useState, useContext } from "react";
import DefaultLayout from "../../layouts/DefaultLayout.jsx";
import PersonalitijdsQuestion from "../../components/formBlocks/PersonalitijdsQuestion.jsx";
import { AuthContext } from "../../contexts/AuthContext.jsx";
import { sendBFIFormData, getTreoForm, getBfiForm, sendTREOFormData} from "../../services/persoonsFormService.js";
import Button from "../../components/button/Button.jsx";
import Spinner from "../../components/spinner/Spinner.jsx";
import { useNavigate } from "react-router-dom";
import { ToastContext } from "../../contexts/ToastContext.jsx";

const PersoonlijkheidForm = (formType) => {
  const { token } = useContext(AuthContext);
  const { setToastInstance } = useContext(ToastContext);
  const [questions, setQuestions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formTitle, setFormTitle] = useState("");
  const navigate = useNavigate();
  
  useEffect(() => {
    loadQuestions(formType);
  }, [formType]);

  const loadQuestions = async (formType) => {
    let formApi;
    let title;
    switch (formType.formQuestions) {
      case "bfi":
        formApi = getBfiForm;
        title = "BFI-2 persoonlijkeheidstest";
        break;
      case "treo":
        formApi = getTreoForm;
        title = "Team Role Experience and Orientation Test";
        break;
      default:
        return;
    }

    try {
      const form = await formApi();
      setQuestions(form.questions);
      setFormTitle(title);
      setLoading(false);
    } catch (error) {
      setError("Er is een fout opgetreden bij het ophalen van de vragen");
    }
  };

  const submitFormData = async () => {
    setIsSubmitting(true);
    let sendFormApi;
    let link;
    switch (formType.formQuestions) {
      case "bfi":
        sendFormApi = sendBFIFormData;
        link = "/profile/data/bfi2/results";
        break;
      case "treo":
        sendFormApi = sendTREOFormData;
        link = "/profile/data/treo/results";
        break;
      default:
        console.log("Invalid form type");
        setIsSubmitting(false);
        return;
    }

    try {
      setLoading(true);
			const formData = {};
			formData.questions = questions;
      await sendFormApi(formData, token);
      navigate(link);
    } catch (error) {
      setError(error.message);
      setToastInstance({ message: "Er is een fout opgetreden bij het versturen van de vragenlijst", type: "error" });
    } finally {
      setIsSubmitting(false);
    }
  };

  const updateQuestionAnswer = (questionIndex, ans) => {
    const answer = ans + 1;
    const updatedQuestions = [...questions];
		updatedQuestions[questionIndex].answer = answer;
		setQuestions(updatedQuestions);
  };

  return (
    <DefaultLayout>
      <div className="cardBlock">
        <h2 className="cardBlock__title">{formTitle}</h2>
        {loading ? <Spinner color={"red"} size={70}/> : (
          <>
            <ul className="cardBlock__questions">
              {questions && questions.map((questionData, index) => (
                <PersonalitijdsQuestion
                  key={index}
                  questionIndex={index}
                  questionData={questionData}
                  updateQuestionAnswer={updateQuestionAnswer}
                />
              ))}
            </ul>
            {error && <p className="error">{error}</p>}
            <div className="cardBlock__footer">
              <Button text="Verstuur" onClick={submitFormData} className={"cardBlock__button"} disabled={isSubmitting} loading={isSubmitting} />
            </div>
          </>
        )}
      </div>
    </DefaultLayout>
  );
};

export default PersoonlijkheidForm;