import React, { useContext, useRef, useState } from "react";
import ProfileLayout from "../../../../../layouts/ProfileLayout";
import Breadcrumbs from "../../../../../components/breadcrumbs/Breadcrumbs";
import { FormProvider, useForm, Controller } from "react-hook-form";
import Fields from "../../../../../components/addForm/Fields";
import { ColorPicker } from "primereact/colorpicker";
import { classNames } from "primereact/utils";
import "./AddForm.css";
import { postForm } from "../../../../../services/formService";
import { AuthContext } from "../../../../../contexts/AuthContext";
import { useTranslation } from 'react-i18next';
import { ToastContext } from "../../../../../contexts/ToastContext";

const AddForm = () => {
  const methods = useForm();
  const [blockError, setBlockError] = useState(false);
  const { token } = useContext(AuthContext);
  const { t } = useTranslation();
  const { setToastInstance } = useContext(ToastContext);

  const onSubmit = (data) => {
    if (data.form.blocks == null || data.form.blocks.length <= 0) {
      setBlockError(true);
      return;
    } else {
      setBlockError(false);

      const mappedData = {
        name: data.form.name,
        description: data.form.description,
        color: data.form.color,
        blocks: data.form.blocks.map((block) => ({
          name: block.name,
          description: block.description,
          questions: block.questions.map((question) => ({
            question: question.question,
          })),
        })),
      };

      postForm(mappedData, token)
        .then((data) => {
          setToastInstance({ message: t("toasts.formadded"), type: "success" });
          methods.reset();
        })
        .catch((error) => {
          setToastInstance({ message: error.message, type: "error" });
          console.error(error);
        });
    }
  };

  return (
    <ProfileLayout>
      <div className="profileContent">
        <Breadcrumbs
          breadcrumbs={[
            { link: "/profile/admin", name: "Admin" },
            { link: "/profile/admin/forms", name: "Forms" },
            { link: "profile/admin/forms/add", name: "Add form" },
          ]}
        />
        <div className="contentContainer">
          <form onSubmit={methods.handleSubmit(onSubmit)} className="addform">
            <div className="formAddBasicInfo">
              <Controller
                name="form.color"
                control={methods.control}
                defaultValue="ff0000"
                render={({ field, fieldState }) => (
                  <ColorPicker
                    name="color"
                    control={methods.control}
                    value={field.value}
                    className={classNames("colorPicker", {
                      "p-invalid": fieldState.error,
                    })}
                    onChange={(e) => field.onChange(e.value)}
                  />
                )}
              />
              <div className="formAddBasicInfo-right">
                <label>
                  <input
                    {...methods.register("form.name", {
                      required: t('errors.required'),
                      minLength: {
                        value: 3,
                        message:
                         t('errors.lt3') ,
                      },
                    })}
                    aria-invalid={
                      methods.formState.errors["name"] ? "true" : "false"
                    }
                    type="text"
                    placeholder={ t('profile.admin.addForm.name') }
                    className="formAddInput"
                  />
                </label>
                {methods.formState.errors["name"] && (
                  <p role="alert" className="error">
                    {methods.formState.errors["name"]?.message}
                  </p>
                )}
                <input
                  {...methods.register("form.description")}
                  placeholder={ t('profile.admin.addForm.desc') }
                  className="formAddInput"
                />
              </div>
            </div>
            <FormProvider {...methods}>
              <Fields />
            </FormProvider>
            {blockError && (
              <p className="error">
                { t('errors.lt1s') }
              </p>
            )}

            <div className="addform-buttons">
              <button
                className="button addform-button resetForm-button"
                onClick={() => methods.reset()}
              >
                { t('profile.admin.addForm.reset') }
              </button>
              <input
                className="button addform-button sendForm-button"
                type="submit"
                value={ t('profile.admin.addForm.send') }
              />
            </div>
          </form>
        </div>
      </div>
    </ProfileLayout>
  );
};

export default AddForm;
