import "../Profile.css";
import gegevens from "../../../../src/assets/icons/edit_square.svg";
import ProfileTitle from "../../../components/profile/ProfileTitle";
import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";
import ProfileLayout from "../../../layouts/ProfileLayout";
import Button from "../../../components/button/Button";
import { useTranslation } from 'react-i18next';
import { ToastContext } from "../../../contexts/ToastContext";

const PersoonlijkeGegevens = () => {
	const { currentUser, updateUserData } = useContext(AuthContext);
	const [inputChanged, setInputChanged] = useState(false);
	const [error, setError] = useState('');
	const { setToastInstance } = useContext(ToastContext);

	const [formData, setFormData] = useState({
		sex: currentUser.sex || null,
		language: currentUser.language || null,
		birthdate: currentUser.birthdate || null,
		marital_state: currentUser.marital_state || null,
		education: currentUser.education || null,
		permission: 2
	})

	const { t } = useTranslation();
	const navigate = useNavigate();
	const today = new Date().toISOString().split('T')[0];

	const handleChangePermission = (event) => {
		const { checked } = event.target;
		setFormData(prevState => ({
			...prevState,
			permission: checked ? 1 : 2
		}));
		setInputChanged(true);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (inputChanged) {
			sendUserData(formData);
		}
		setInputChanged(false);
	};

	const deleteData = () => {
		setFormData(prevState => ({
			...prevState,
			permission: 3
		}));
		sendUserData({ permission: 3, sex: "x" });
	}

	const sendUserData = async (formData) => {
		Object.keys(formData).forEach(key => {
			if (formData[key] === "") {
				formData[key] = null;
			}
		});

		updateUserData(formData)
			.then(() => {
				setInputChanged(false);
				setToastInstance({ message: t("toasts.editprofile"), type: "success" });
				setError('');
			})
			.catch((error) => {
				setError(error.message);
				setToastInstance({ message: error.message, type: "error" });
			});
	};

	return (
		<ProfileLayout>
			<div className="profileContent">
				<ProfileTitle logo={gegevens} title={t('profile.PersoonlijkeGegevens.title')} subtitle={t('profile.PersoonlijkeGegevens.subtitle')} />
				<div className="contentContainer profileSection">
					{error && <p className="error">{error}</p>}
					<form onSubmit={handleSubmit} className='profile-form'>
						<div>
							<label className='label checkboxLabel' style={{ marginBottom: 5 }}>
								<input
									type="checkbox"
									className="hidden checkboxInput"
									name="gdpr_permission"
									checked={formData.permission === 1}
									onChange={handleChangePermission}
								/>
								<span className="checkboxCustom"></span>
								<span className="checkboxText">{t('profile.PersoonlijkeGegevens.checkboxText')}</span>
							</label>
							<div className="dataUsage" onClick={(e) => e.stopPropagation()}>{t('profile.PersoonlijkeGegevens.datausage')} <br />
								<Link className="link dataUsage-info" to="/privacy" style={{ marginTop: 5, display: "inline-block" }}>{t('profile.PersoonlijkeGegevens.readMore')}</Link>
								<p className='link dataUsage-delete' style={{ color: "var(--red-color)", marginTop: 5 }} onClick={() => deleteData()}>{t('profile.PersoonlijkeGegevens.deleteData')}</p>
							</div>
						</div>
						<div className="personalityTestContainer" >
							<label className="label">
								{t('profile.PersoonlijkeGegevens.tests')}
								<div className="label-flex">
									{/* vanwege een bug doe ik dit ipv <Button /> */}
									<div onClick={() => navigate('/profile/data/bfi2')}  className="button button-small button-blue">{t('profile.PersoonlijkeGegevens.testoption.bfi2')}</div>
									<div onClick={() => navigate('/profile/data/Treo')}  className="button button-small button-blue">{t('profile.PersoonlijkeGegevens.testoption.treo')}</div>
								</div>
							</label>
						</div>
						
						<div className="label-persoonlijkegegevens">
							<label className='label' style={{ gridArea: "sex" }}>
								{t('profile.PersoonlijkeGegevens.sex')}
								<select 
									name="sex" 
									onChange={(e) => { setFormData({ ...formData, sex: e.target.value }); setInputChanged(true) }} 
									value={formData.sex === null ? "" : formData.sex} 
									className='input'
								>
									<option disabled={formData.sex === null} value="">{t('profile.PersoonlijkeGegevens.select')}</option>
									<option value="m">{t('profile.PersoonlijkeGegevens.sexoption.m')}</option>
									<option value="v">{t('profile.PersoonlijkeGegevens.sexoption.v')}</option>
									<option value="x">{t('profile.PersoonlijkeGegevens.sexoption.a')}</option>
								</select>
							</label>
							<label className="label" style={{gridArea: "language"}}>
								{t('profile.PersoonlijkeGegevens.language')}
								<select
									name="language"
									onChange={(e) => {
										setFormData({ ...formData, language: e.target.value });
										setInputChanged(true);
									}}
									value={formData.language === null ? "" : formData.language}
									className="input"
								>
									<option disabled={formData.language === null} value="">{t('profile.PersoonlijkeGegevens.select')}</option>
									<option value="dutch">{t('profile.PersoonlijkeGegevens.languageoption.NL')}</option>
									<option value="french">{t('profile.PersoonlijkeGegevens.languageoption.FR')}</option>
									<option value="german">{t('profile.PersoonlijkeGegevens.languageoption.DE')}</option>
									<option value="english">{t('profile.PersoonlijkeGegevens.languageoption.EN')}</option>
									<option value="other">{t('profile.PersoonlijkeGegevens.languageoption.X')}</option>
								</select>
							</label>
							<label className="label" style={{gridArea: "birthdate"}}>
								{t('profile.PersoonlijkeGegevens.birthdate')}
								<input
									type="date"
									name="birthdate"
									max={today}
									onChange={(e) => {
										setFormData({ ...formData, birthdate: e.target.value });
										setInputChanged(true);
									}}
									value={formData.birthdate === null ? "" : formData.birthdate}
									className="input"
								/>
							</label>
							<label className='label' style={{gridArea: "burgerlijkestaat"}}>
								{t('profile.PersoonlijkeGegevens.burgerlijkestaat')}
								<select 
									name="marital_state" 
									onChange={(e) => { setFormData({ ...formData, marital_state: e.target.value }); setInputChanged(true) }} 
									value={formData.marital_state === null ? "" : formData.marital_state} 
									className='input'
									>
									<option disabled={formData.marital_state === null} value="">{t('profile.PersoonlijkeGegevens.select')}</option>
									<option value="single">{t('profile.PersoonlijkeGegevens.burgerlijkestaatOption.ongehuwd')}</option>
									<option value="married">{t('profile.PersoonlijkeGegevens.burgerlijkestaatOption.gehuwd')}</option>
									<option value="cohabiting">{t('profile.PersoonlijkeGegevens.burgerlijkestaatOption.samenwonend')}</option>
									<option value="divorced">{t('profile.PersoonlijkeGegevens.burgerlijkestaatOption.gescheiden')}</option>
									<option value="widow">{t('profile.PersoonlijkeGegevens.burgerlijkestaatOption.weduw(e)naar')}</option>
									<option value="engaged">{t('profile.PersoonlijkeGegevens.burgerlijkestaatOption.verloofd')}</option>
								</select>
							</label>
							<label className='label' style={{gridArea: "diploma"}}>
								{t('profile.PersoonlijkeGegevens.diploma')}
								<select 
									name="education" 
									onChange={(e) => { setFormData({ ...formData, education: e.target.value }); setInputChanged(true) }} 
									value={formData.education === null ? "" : formData.education} 
									className='input'
								>
									<option disabled={formData.education === null} value="">{t('profile.PersoonlijkeGegevens.select')}</option>
									<option value="elementary">{t('profile.PersoonlijkeGegevens.diplomaoption.elementary')}</option>
									<option value="highschool">{t('profile.PersoonlijkeGegevens.diplomaoption.sec')}</option>
									<option value="college">{t('profile.PersoonlijkeGegevens.diplomaoption.college')}</option>
									<option value="other">{t('profile.PersoonlijkeGegevens.diplomaoption.other')}</option>
								</select>
							</label>
						</div>
						<Button text={t('profile.PersoonlijkeGegevens.change')} disabled={!inputChanged} color={"blue"} type="submit" />
					</form>
				</div>
			</div>
		</ProfileLayout>
	);
}

export default PersoonlijkeGegevens;
