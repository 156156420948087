import React, { useState } from "react";
import "./SearchFilter.css";
import plus from "../../assets/icons/plus.svg";
import search from "../../assets/icons/search.svg";
import setting4 from "../../assets/icons/setting4.svg";
import refresh from "../../assets/icons/refresh.svg";
import { useTranslation } from 'react-i18next';


const SearchFilter = ({
  searchValue,
  setSearchValue,
  sortingOption,
  setSortingOption,
  formStatus,
  setFormStatus,
  reload,
  navigateToAdd
}) => {

  const { t } = useTranslation();

  const [showPopover, setShowPopover] = useState(false);

  const togglePopover = () => {
    setShowPopover(!showPopover);
  };

  const handleSortingChange = (e) => {
    setSortingOption(e.target.value);
  };

  const handleFormStatusChange = (e) => {
    setFormStatus(e.target.value);
  };

  return (
    <div className="filter-search-container">
      <div className="search-container">
        <div className="input-container">
          <input
            type="text"
            className="input-field input"
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
            placeholder={ t('searchFilter.search') }
          />
          <img src={search} alt="Search Icon" className="input-img" />
        </div>
        <div className="search-button-container">
          <button className="layered-grid button buttonGrid" onClick={togglePopover} title={ t('searchFilter.buttons.filter') }>
            <div></div>
            <img src={setting4} width="15px" height="15px" />
          </button>

          <button onClick={navigateToAdd} className="layered-grid buttonGrid button add" title={ t('searchFilter.buttons.add') }>
            <img src={plus} width="15px" height="15px" />
          </button>
          
          <div className="layered-grid button buttonGrid refresh" onClick={reload}  title={ t('searchFilter.buttons.refresh') }>
              <img src={refresh} width="15px" height="15px" />
            </div> 
        </div>
        </div>
        

      {showPopover && (
        <div className="popover">
          <div>
            <p>{ t('searchFilter.chooseSort') }</p>
            <div>
              <label>
                <input
                  type="radio"
                  value="A-Z"
                  checked={sortingOption === "A-Z"}
                  onChange={handleSortingChange}
                />
                A-Z
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  value="Z-A"
                  checked={sortingOption === "Z-A"}
                  onChange={handleSortingChange}
                />
                Z-A
              </label>
            </div>
          </div>
          <div>
            <p>{ t('searchFilter.formStatus') }</p>
            <div>
              <label>
                <input
                  type="radio"
                  value="all"
                  checked={formStatus === "all"}
                  onChange={handleFormStatusChange}
                />
                { t('searchFilter.all') }
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  value="open"
                  checked={formStatus === "open"}
                  onChange={handleFormStatusChange}
                />
                { t('searchFilter.open') }
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  value="closed"
                  checked={formStatus === "closed"}
                  onChange={handleFormStatusChange}
                />
                { t('searchFilter.closed') }
              </label>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchFilter;
