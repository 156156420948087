import { BrowserRouter as Router, Routes, Route, Form } from "react-router-dom";
import Home from "./pages/home/Home";
import { AuthProvider } from "./contexts/AuthContext";
import Notfound from "./pages/notfound/Notfound";
import Vragenlijst from "./pages/vragenlijst/Vragenlijst";
import { VragenlijstProvider } from "./contexts/VragenlijstContext";
import Auth from "./pages/auth/Auth";
import UserNotFound from "./pages/notfound/UserNotFound";
import Privacy from "./pages/privacy/Privacy";
import Algemeen from "./pages/profile/tabs/Algemeen";
import PersoonlijkeGegevens from "./pages/profile/tabs/PersoonlijkeGegevens";
import Wachtwoord from "./pages/profile/tabs/Wachtwoord";
import Dashboard from "./pages/profile/tabs/admin/Dashboard";
import Users from "./pages/profile/tabs/admin/Users";
import Forms from "./pages/profile/tabs/admin/forms/Forms";
import Groups from "./pages/profile/tabs/admin/groups/Groups";
import AddForm from "./pages/profile/tabs/admin/forms/AddForm";
import Confirmation from "./pages/confirmation/Confirmation";
import Gemiddeldes from "./pages/vragenlijst/gemiddeldes/Gemiddeldes";
import AddGroup from "./pages/profile/tabs/admin/groups/AddGroup";
import PersoonlijkheidForm from "./pages/persoonsForms/PersoonlijkheidForm";
import Assignments from "./pages/profile/tabs/admin/assignments/Assignments";
import AddAssignment from "./pages/profile/tabs/admin/assignments/AddAssignment";
import VragenlijstView from "./pages/vragenlijst/VragenlijstView";
import VragenlijstAssignment from "./pages/vragenlijst/VragenlijstAssignment";
import Data from "./pages/profile/tabs/admin/Data";
import Results from "./pages/persoonsForms/results/Results";
import AssignmentGemiddeldes from "./pages/profile/tabs/admin/assignments/gemiddeldes/AssignmentGemiddeldes";
import { ToastProvider } from "./contexts/ToastContext";

const App = () => {
  return (
    <Router>
      <ToastProvider>
        <AuthProvider>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/*" element={<Notfound />} />
            <Route exact path="/usernotfound" element={<UserNotFound />} />
            <Route exact path="/sign-in" element={<Auth />} />
            <Route exact path="/sign-up" element={<Auth />} />
            <Route exact path="/profile" element={<Algemeen />} />
            <Route exact path="/profile/algemeen" element={<Algemeen />} />
            <Route exact path="/profile/data" element={<PersoonlijkeGegevens />} />
            <Route exact path="/profile/data/bfi2" element={<PersoonlijkheidForm formQuestions="bfi" />} /> 
            <Route exact path="/profile/data/Treo" element={<PersoonlijkheidForm formQuestions="treo" />} />
            <Route exact path="/profile/data/bfi2/results" element={<Results formType="bfi" />} />
            <Route exact path="/profile/data/Treo/results" element={<Results formType="treo" />} />
            <Route exact path="/profile/data/Attitude" element={<PersoonlijkheidForm formQuestions="attitude" />} />
            <Route exact path="/profile/data/Motivatie" element={<PersoonlijkheidForm formQuestions="motivatie" />} />
            <Route exact path="/profile/password" element={<Wachtwoord />} />
            <Route exact path="/profile/admin" element={<Dashboard />} />
            <Route exact path="/profile/admin/users" element={<Users />} />
            <Route exact path="/profile/admin/groups" element={<Groups />} />
            <Route exact path="/profile/admin/groups/add" element={<AddGroup />} />
            <Route exact path="/profile/admin/forms" element={<Forms />} />
            <Route exact path="/profile/admin/forms/add" element={<AddForm />} />
            <Route exact path="/profile/admin/assignments" element={<Assignments />} />
            <Route exact path="/profile/admin/assignments/:assignmentId/gemiddeldes" element={<AssignmentGemiddeldes />} />
            <Route exact path="/profile/admin/assignments/add" element={<AddAssignment />} />
            <Route exact path="/profile/admin/data" element={<Data/>} />
            <Route exact path="/profile/*" element={<Algemeen />} />
            <Route exact path="/profile/admin/*" element={<Dashboard />} />
            <Route exact path="vragenlijst/:assignmentId/*" element={<VragenlijstProvider><VragenlijstAssignment /></VragenlijstProvider>} />
            <Route exact path="vragenlijst/view/:formId" element={<VragenlijstProvider><VragenlijstView /></VragenlijstProvider>} /> 
            <Route exact path="vragenlijst/:assignmentId/gemiddeldes" element={<VragenlijstProvider><Gemiddeldes /></VragenlijstProvider>} />
            <Route exact path="/confirmation/:assignmentId/*" element={<Confirmation />} />
            <Route exact path="/privacy" element={<Privacy />} />
          </Routes>
        </AuthProvider>
      </ToastProvider>
    </Router>
  );
}

export default App;