import React, { useContext, useEffect, useState } from "react";
import ProfileLayout from "../../../../../layouts/ProfileLayout";
import "./Assignments.css";
import { getGroupsOfTeacher, postGroup } from "../../../../../services/groupsService";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../../contexts/AuthContext";
import { getForms } from "../../../../../services/formService";
import Breadcrumbs from "../../../../../components/breadcrumbs/Breadcrumbs";
import Button from "../../../../../components/button/Button";
import { postAssignment } from "../../../../../services/assignmentService";
import { useTranslation } from 'react-i18next';
import { ToastContext } from "../../../../../contexts/ToastContext";

const AddAssignment = () => {
    const breadcrumbs = [ {link: "/profile/admin", name: "Admin"}, {link: "/profile/admin/assignments", name: "Assignments"}, {link: "/profile/admin/assignments/add", name: "Voeg assignment toe"} ];
    // GENERAL
    const [error, setError] = useState(null);
    const { token } = useContext(AuthContext);
    const { setToastInstance } = useContext(ToastContext);
    const navigate = useNavigate();
    // DATA
    const [forms, setForms] = useState(null);
    const [groups, setGroups] = useState([]);
    const [availableGroups, setAvailableGroups] = useState([]);
    // FORMDATA
    const [formData, setFormData] = useState({
        name: "",
        selectedForm: "",
        selectedGroups: []
    });

    const { t } = useTranslation();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        await getForms(token).then((forms) => {
            setForms(forms);
        }).catch((error) => {
            setError(error.message);
        });
        await getGroupsOfTeacher(token).then((groups) => {
            setGroups(groups);
            setAvailableGroups(groups);
        }).catch((error) => {
            setError(error.message);
        });
    };

    const addGroupFromSelection = (group) => {
        setFormData({...formData, selectedGroups: [...formData.selectedGroups, group]});
        setAvailableGroups(availableGroups.filter((availableGroup) => availableGroup.group_id !== group.group_id));
    };

    const removeGroupFromSelection = (group) => {
        setFormData({...formData, selectedGroups: formData.selectedGroups.filter((selectedGroup) => selectedGroup.group_id !== group.group_id)});
        setAvailableGroups([...availableGroups, group]);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        postAssignment(formData, token).then(() => {
            setToastInstance({ message: t("toasts.assignmentadded"), type: "success" });
            navigate("/profile/admin/assignments");
        }).catch((error) => {
            setToastInstance({ message: error.message, type: "error" });
            setError(error.message);
        });
    };

    return (
        <ProfileLayout>
            <div className="profileContent">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
                <div className="contentContainer">
                    {error && <p className="error">{error}</p>}
                    <p className="contentContainer-top">{ t('profile.admin.addAssignment.desc') }</p>
                    <form className="profile-form addAssignment-container">
                        <label className="label chooseForm-label">
                        { t('profile.admin.addAssignment.chName') }
                            <input className="input" type="text" placeholder={ t('profile.admin.addAssignment.tName') } value={formData.name} onChange={(e) => setFormData({...formData, name: e.target.value})} />
                        </label>
                        <label className="label chooseForm-label">
                        { t('profile.admin.addAssignment.chForm') }
                            <select className="input" value={formData.selectedForm} onChange={(e) => setFormData({...formData, selectedForm: e.target.value})}>
                                <option value="" disabled>{ t('profile.admin.addAssignment.sForm') }</option>
                                {forms && forms.map((form) => (
                                    <option className="addAssignment-option" key={form.form_id} value={form.form_id}>{form.name}</option>
                                ))}
                            </select>
                        </label>
                        <div className={"grouppicker-container " + (formData.name.length <= 0 || !formData.selectedForm ? "hidden" : "")}>
                            <label className="label chooseGroup-label">{ t('profile.admin.addAssignment.sGroup') }</label>
                            <div className="grouppicker">
                                <div className="grouppicker-field grouppicker-allgroups">
                                    <div className="grouppicker-field__wrapper">
                                        {groups.length === 0 && <p className="nogroups">{ t('profile.admin.addAssignment.noGroup') }</p>}
                                        {availableGroups.map((group) => (
                                            <div key={group.group_id} id={group.group_id} className="grouppicker-item" onClick={() => addGroupFromSelection(group)}>
                                                <p className="">{group.name}</p>
                                                <div className="grouppicker-item__icon"></div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="grouppicker-arrow"></div>
                                <div className="grouppicker-field grouppicker-selectedGroups">
                                    <div className="grouppicker-field__wrapper">
                                        {formData.selectedGroups.map((group) => (
                                            <div key={group.group_id} className="grouppicker-item" onClick={() => removeGroupFromSelection(group)}>
                                                <p>{group.name}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <Button text={ t('profile.admin.assignments.button') } color="blue" className="" onClick={handleSubmit} disabled={formData.name.length <= 0 || !formData.selectedForm || formData.selectedGroups.length === 0}/>
                        </div>
                    </form>
                </div>
            </div>
        </ProfileLayout>
    );
};

export default AddAssignment;