import React, { createContext, useEffect, useState } from 'react';
import { Toaster, toast } from 'react-hot-toast';

export const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
    const [toastInstance, setToastInstance] = useState({ message: "", type: "" });
    
    useEffect(() => {
        if (toastInstance.message) {
            if (toastInstance.type === "error") {
                toast.error(toastInstance.message);
            } else {
                toast.success(toastInstance.message);
            }
        }
    }, [toastInstance]);

    return (
      	<ToastContext.Provider
			value={{
                setToastInstance
			}}
      	>
            <Toaster toastOptions={{
                duration: 4500,
            }}/>
        	{children}
      	</ToastContext.Provider>
    );
  };