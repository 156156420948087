import React, { useContext, useEffect, useState } from "react";
import DefaultLayout from "../../../layouts/DefaultLayout";
import { AuthContext } from "../../../contexts/AuthContext";
import { getBfiResults, getTreoResults } from "../../../services/persoonsFormService";
import Spinner from "../../../components/spinner/Spinner";
import { Bar, Radar } from "react-chartjs-2";

const Results = ({ formType }) => {
    const { token } = useContext(AuthContext);
    const [data, setData] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [formTitle, setFormTitle] = useState("");

    useEffect(() => {
        loadResults();
    }, [formType]);

    const loadResults = async () => {
        let formApi;
        let title;
        switch (formType) {
            case "bfi":
                formApi = getBfiResults;
                title = "BFI-2 personality results";
                break;
            case "treo":
                formApi = getTreoResults;
                title = "Team Role Experience and Orientation Personality results";
                break;
            default:
                console.log("Invalid form type");
                return;
        }

        try {
            setLoading(true);
            const responseData = await formApi(token);
            setData(responseData);
            setFormTitle(title);
            setLoading(false);
        } catch (error) {
            setError("An error occurred while fetching the data");
        }
    };

    const renderBfiCharts = () => {
        return (
            <>
                <div>
                    <h2>Domain Personalities</h2>
                    <Bar data={getBfiChartData("domainPersonalities")} />
                </div>
                <div>
                    <h2>Facet Personalities</h2>
                    <Bar data={getBfiChartData("facetPersonalities")} />
                </div>
                <div>
                    <h2>Radar Chart - Facet Personalities</h2>
                    <Radar data={getBfiRadarData()} />
                </div>
            </>
        );
    };

    const renderTreoCharts = () => {
        const radarData = {
            labels: Object.keys(data),
            datasets: [
                {
                    label: "Personality Traits",
                    data: Object.values(data),
                    backgroundColor: "rgba(54, 162, 235, 0.2)",
                    borderColor: "rgba(54, 162, 235, 1)",
                    pointBackgroundColor: "rgba(54, 162, 235, 1)",
                    pointBorderColor: "#fff",
                    pointHoverBackgroundColor: "#fff",
                    pointHoverBorderColor: "rgba(54, 162, 235, 1)",
                },
            ],
        };
    
        return (
            <Radar data={radarData} />
        );
    };

    const getBfiChartData = (key) => {
        const bfiData = data[key];
        return {
            labels: Object.keys(bfiData),
            datasets: [
                {
                    label: "Personalities",
                    data: Object.values(bfiData),
                    backgroundColor: "rgba(54, 162, 235, 0.5)",
                    borderColor: "rgba(54, 162, 235, 1)",
                    borderWidth: 1,
                },
            ],
        };
    };

    const getBfiRadarData = () => {
        const facetPersonalities = data.facetPersonalities;
        return {
            labels: Object.keys(facetPersonalities),
            datasets: [
                {
                    label: "Facet Personalities",
                    data: Object.values(facetPersonalities),
                    backgroundColor: "rgba(255, 99, 132, 0.2)",
                    borderColor: "rgba(255, 99, 132, 1)",
                    pointBackgroundColor: "rgba(255, 99, 132, 1)",
                    pointBorderColor: "#fff",
                    pointHoverBackgroundColor: "#fff",
                    pointHoverBorderColor: "rgba(255, 99, 132, 1)",
                },
            ],
        };
    };

    return (
        <DefaultLayout>
            <div className="layout-container animation" style={{ maxWidth: "60rem" }}>
                <div className="centered__container">
                    <h1>{formTitle}</h1>
                </div>
                <div className="centered__container">
                    {loading ? (
                        <Spinner color={"red"} size={70} />
                    ) : formType === "bfi" ? (
                        renderBfiCharts()
                    ) : formType === "treo" ? (
                        renderTreoCharts()
                    ) : (
                        <div>No data to display</div>
                    )}
                </div>
            </div>
        </DefaultLayout>
    );
};

export default Results;
