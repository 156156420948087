import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { VragenlijstContext } from "../../contexts/VragenlijstContext";
import { getUserForm } from "../../services/formService";
import Vragenlijst from "./Vragenlijst";
import SubscribePage from "./subscribePage/SubscribePage";
import FormLayout from "../../layouts/FormLayout";
import VragenlijstDrawer from "../../components/drawer/drawercontents/VragenlijstDrawer";
import VragenlijstAssignmentBlock from "../../components/formBlocks/VragenlijstAssignmentBlock";

const VragenlijstAssignment = () => {
    const { assignmentId } = useParams();
    const { token } = useContext(AuthContext); 
    const [notSubscribed, setNotSubscribed] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const { setFormDataForAssignment } = useContext(VragenlijstContext);
    
    useEffect(() => {
        if(!notSubscribed) {
            fetchUserFormData();
        }
    } , [notSubscribed])

    const fetchUserFormData = async () => {
        setIsLoading(true);
        try {
            const data = await getUserForm(assignmentId, token);
            await setFormDataForAssignment(data);
            setIsLoading(false);
            setNotSubscribed(false);
        } catch (error) {
            if (error.message === "USER-NOT-SUBSCRIBED") {
                setNotSubscribed(true);
                return;
            }
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    }
    
    const renderPage = () => {
        if(notSubscribed) {
            return (
                <SubscribePage assignmentId={assignmentId} setIsLoading={setIsLoading} setNotSubscribed={setNotSubscribed}/>
            )
        } else {
            return (
                <FormLayout drawerChildren={<VragenlijstDrawer/>}>
                    <VragenlijstAssignmentBlock />
                </FormLayout>
            )
        }
    }
    
    return (
        <Vragenlijst error={error} isLoading={isLoading}>
            {renderPage()}
        </Vragenlijst>
    );
}

export default VragenlijstAssignment;