import React, { useContext } from "react"; 
import "./AdminSuite.css";
import ProfileTitle from "../../../../components/profile/ProfileTitle";
import userTie from "../../../../assets/icons/user-tie-solid.svg";
import assignment from "../../../../assets/icons/assignment.svg";
import forms from "../../../../assets/icons/forms.svg";
import grade from "../../../../assets/icons/grade.svg";
import groups from "../../../../assets/icons/groups.svg";
import users from "../../../../assets/icons/personSearch.svg";
import AdminsuiteItem from "../../../../components/adminSuite/AdminsuiteItem";
import ProfileLayout from "../../../../layouts/ProfileLayout";
import { AuthContext } from "../../../../contexts/AuthContext";
import { useTranslation } from "react-i18next";

const Dashboard = ({ setActiveAdminTab }) => {
  const { t } = useTranslation();  
  const { userAdminRole } = useContext(AuthContext);


    const renderAdminSuiteInterface = () => {
      // eslint-disable-next-line default-case
      switch (userAdminRole.role_id) {
        case 1:
          return (
            <div className="contentContainer adminSuite">
              <h3 className="adminSuite-groupTitle" style={{ gridRow: "1" }}>
                {t("profile.admin.dashboard.student")}
              </h3>
            </div>
          );
        case 2:
          return (
            <div className="contentContainer adminSuite">
              <h3 className="adminSuite-groupTitle" style={{ gridRow: "1" }}>
                {t("profile.admin.dashboard.usrmangnmnt")}
              </h3>
              <AdminsuiteItem
                logo={groups}
                itemName="groups"
                title={t("profile.admin.dashboard.groups")}
                setActiveAdminTab={setActiveAdminTab}
                style={{ gridRow: "2", gridColumn: 1 / 2 }}
              />
              <h3
                className="adminSuite-groupTitle"
                style={{ gridRow: "3", gridColumn: "1/3" }}
              >
                {t("profile.admin.dashboard.formsmangnmnt")}
              </h3>
              <AdminsuiteItem
                logo={forms}
                itemName="forms"
                title={t("profile.admin.dashboard.forms")}
                setActiveAdminTab={setActiveAdminTab}
                style={{ gridRow: "4", gridColumn: 1 / 2 }}
              />
              <AdminsuiteItem
                logo={assignment}
                itemName="assignments"
                title={t("profile.admin.dashboard.assignments")}
                setActiveAdminTab={setActiveAdminTab}
                style={{ gridRow: "4", gridColumn: 1 / 2 }}
              />
            </div>
          );
        case 3:
          return (
            <div className="contentContainer adminSuite">
              <h3 className="adminSuite-groupTitle" style={{ gridRow: "1" }}>
                {t("profile.admin.dashboard.data")}
              </h3>
              <AdminsuiteItem
                logo={grade}
                itemName="data"
                title={t("profile.admin.dashboard.score")}
                setActiveAdminTab={setActiveAdminTab}
                style={{ gridRow: "2", gridColumn: 1 / 2 }}
              />
            </div>
          );
        case 4:
        case 5:
          return (
            <div className="contentContainer adminSuite">
              <h3 className="adminSuite-groupTitle" style={{ gridRow: "1" }}>
                {t("profile.admin.dashboard.usrmangnmnt")}
              </h3>
              <AdminsuiteItem
                logo={users}
                itemName="users"
                title={t("profile.admin.dashboard.users")}
                setActiveAdminTab={setActiveAdminTab}
                style={{ gridRow: "2", gridColumn: 1 / 2 }}
              />
              <AdminsuiteItem
                logo={groups}
                itemName="groups"
                title={t("profile.admin.dashboard.groups")}
                setActiveAdminTab={setActiveAdminTab}
                style={{ gridRow: "2", gridColumn: 1 / 2 }}
              />
              <h3 className="adminSuite-groupTitle" style={{ gridRow: "3" }}>
                {t("profile.admin.dashboard.formsmangnmnt")}
              </h3>
              <AdminsuiteItem
                logo={forms}
                itemName="forms"
                title={t("profile.admin.dashboard.forms")}
                setActiveAdminTab={setActiveAdminTab}
                style={{ gridRow: "4", gridColumn: 1 / 2 }}
              />
              <AdminsuiteItem
                logo={assignment}
                itemName="assignments"
                title={t("profile.admin.dashboard.assignments")}
                setActiveAdminTab={setActiveAdminTab}
                style={{ gridRow: "4", gridColumn: 1 / 2 }}
              />
              <h3 className="adminSuite-groupTitle" style={{ gridRow: "5" }}>
                {t("profile.admin.dashboard.scoremangnmnt")}
              </h3>
              <AdminsuiteItem
                logo={grade}
                itemName="data"
                title={t("profile.admin.dashboard.score")}
                setActiveAdminTab={setActiveAdminTab}
                style={{ gridRow: "6", gridColumn: 1 / 2 }}
              />
            </div>
          );
      }
    };

    return (
      <ProfileLayout>
        <div className="profileContent">
          <ProfileTitle
            logo={userTie}
            title={userAdminRole.name}
            subtitle={t("profile.admin.dashboard.description")}
          />
          {renderAdminSuiteInterface()}
        </div>
      </ProfileLayout>
    );
};

export default Dashboard;
