import React from "react";
import { useController } from "react-hook-form";

export const Input = ({ name, defaultValue, placeholder }) => {
  const { field: inputProps } = useController({
    name,
    defaultValue: defaultValue ?? "",
   
  });
  return <input {...inputProps} className="formAddInput" placeholder= {placeholder} />;
};
