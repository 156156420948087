import React, { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Input } from "./Input";
import NestedArray from "./QuestionFields";
import Delete from "../../assets/icons/delete.svg";
import Plus from "../../assets/icons/plus.svg";
import { useTranslation } from 'react-i18next';

export default function Fields() {
  const { register, formState: { errors }  } = useFormContext();
  const { fields: blocks, append, remove } = useFieldArray({
    name: "form.blocks",
  });
  const { t } = useTranslation();

  return (
    <>
      <ul className="newBlocks">
        {blocks.map((item, index) => {
          return (
            <li key={item.id}>
              <div className="formNewBlock">
              <Input
                  {...register(`form.blocks.${index}.name`, {
                    required: t('errors.required'),
                    minLength: {
                      value: 3,
                      message: t('errors.lt3'),
                    }
                  })}
                  defaultValue={item.name}
                  placeholder={ t('profile.admin.addForm.blockName') }
                />
                {errors?.form?.blocks?.[index]?.name && (
                  <p className="error">{errors.form.blocks[index].name.message}</p>
                )}
              <Input
                {...register(`form.blocks.${index}.description`)} 
                defaultValue={item.description}
                placeholder={ t('profile.admin.addForm.blockDesc') }
              />

              <button className="formAddButton delete-block-button" type="button" onClick={() => remove(index)}>
                <img
                  src={Delete} 
                  title={ t('profile.admin.addForm.blockDel') }
                  alt={ t('profile.admin.addForm.blockDel') }
                />
              </button>
                <NestedArray nestIndex={index} />
              </div>
            </li>
          );
        })}
      </ul>

      <section>
        <button
          className="button add-block-button"
          title={ t('profile.admin.addForm.blockAdd') }
          type="button"
          onClick={() => {
            append({
              name: "",
              description: "",
              questions: [],
            });
          }}
        >
          { t('profile.admin.addForm.blockAdd') }
         <img
            src={Plus}  
            alt="add"
            className="plusIcon"
          />
        </button>
      </section>
    </>
  );
}
