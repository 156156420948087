import environment from '../environment.json';

const API_BASE_URL = process.env.REACT_APP_API_URL;

export async function getTreoForm() {
    try {
        const response = await fetch(`${API_BASE_URL}/treo/question`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        return response.json();
    } catch (error) {
        console.error(error);
        throw new Error("Er was een probleem bij het ophalen van de gegevens.");
    }
}

export async function getBfiForm() {
    try {
        const response = await fetch(`${API_BASE_URL}/bfi/question`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        return response.json();
    } catch (error) {
        throw new Error("Er was een probleem bij het ophalen van de gegevens.");
    }
}

export async function getAttitudeForm() {
    try {
        const response = await fetch(`${API_BASE_URL}/attitude_forms`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        });
        return response.json();
    } catch (error) {
        console.error(error);
        throw new Error("Er was een probleem bij het ophalen van de gegevens.");
    }
}

export async function getMotivatieForm() {
    try {
        const response = await fetch(`${API_BASE_URL}/motivatie_forms`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        });
        return response.json();
    } catch (error) {
        console.error(error);
        throw new Error("Er was een probleem bij het ophalen van de gegevens.");
    }
}

export async function sendBFIFormData(formData, token) {
  try {
    const response = await fetch(`${API_BASE_URL}/bfi/form`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(formData),
    });
  
    if (response.status === 422 ) {
      throw new Error("Gelieve alle vragen in te vullen");
    } else if (!response.ok) {
      throw new Error("Er was een probleem bij het verzenden van de gegevens.");
    }
  
    return response.json();
	} catch (error) {
		throw error;
	}
}
  
export async function sendTREOFormData(formData, token) {
	try {
		const response = await fetch(`${API_BASE_URL}/treo/form`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify(formData),
		}).catch((error) => {
			console.log(error);
		});

		if (response.status === 422 ) {
			throw new Error("Gelieve alle vragen in te vullen");
		} else if (!response.ok) {
			throw new Error("Er was een probleem bij het verzenden van de gegevens.");
		}

		return response.json();
	} catch (error) {
		throw error;
	}
}
  
export async function sendAtidudeFormData(formData, token) {
	try {
		const response = await fetch(`${API_BASE_URL}/treo_forms`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify(formData),
		}).catch((error) => {
			console.log(error);
		});

		if (response.status === 422 ) {
			throw new Error("Gelieve alle vragen in te vullen");
		} else if (!response.ok) {
			throw new Error("Er was een probleem bij het verzenden van de gegevens.");
		}

		return response.json();
	} catch (error) {
		throw error;
	}
}
  
export async function sendMotivatieFormData(formData, token) {
	try {
		const response = await fetch(`${API_BASE_URL}/motivatie_forms`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify(formData),
		}).catch((error) => {
			console.log(error);
		});

		if (response.status === 422 ) {
			throw new Error("Gelieve alle vragen in te vullen");
		} else if (!response.ok) {
			throw new Error("Er was een probleem bij het verzenden van de gegevens.");
		}

		return response.json();
	} catch (error) {
		throw error;
	}
}

export async function getBfiResults(token) {
	try {
		const response = await fetch(`${API_BASE_URL}/bfi/personality`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
		if(response.status === 404) {
			throw new Error("Er zijn geen resultaten gevonden");
		}
		return response.json();
	} catch (error) {
		throw new Error("Er was een probleem bij het ophalen van de gegevens.");
	}
}

export async function getTreoResults(token) {
	try {
		const response = await fetch(`${API_BASE_URL}/treo/personality`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
		if(response.status === 404) {
			throw new Error("Er zijn geen resultaten gevonden");
		}
		return response.json();
	} catch (error) {
		throw new Error("Er was een probleem bij het ophalen van de gegevens.");
	}
}
