import React, { useContext, useState } from "react";
import ProfileLayout from "../../../../../layouts/ProfileLayout";
import "./Groups.css";
import { postGroup } from "../../../../../services/groupsService";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../../contexts/AuthContext";
import Button from "../../../../../components/button/Button";
import Breadcrumbs from "../../../../../components/breadcrumbs/Breadcrumbs";
import { useTranslation } from 'react-i18next';
import { ToastContext } from "../../../../../contexts/ToastContext";


const AddGroup = () => {
    const breadcrumbs = [
        { link: "/profile/admin", name: "Admin" },
        { link: "/profile/admin/groups", name: "Groups" },
        { link: "/profile/admin/groups/add", name: "Add Group" }
    ];
    const [name, setName] = useState('');
    const [maxMembers, setMaxMembers] = useState(15);
    const [error, setError] = useState('');
    const { token } = useContext(AuthContext);
    const { setToastInstance } = useContext(ToastContext);
    const navigate = useNavigate();

    const { t } = useTranslation();

    const handleSubmit = (e) => {
        e.preventDefault();

        if (name.length <= 0) {
            setError( t('errors.notEmpty') );
            return;
        } else if (name.length > 255) {
            setError( t('errors.gt255') );
            return;
        }
        const request = {
            name: name,
            memberlimit: maxMembers
        }
        postGroup(request, token)
            .then((response) => {
                setToastInstance({ message: t("toasts.groupadded"), type: "success" });
                navigate("/profile/admin/groups")
            })
            .catch((error) => {
                setToastInstance({ message: error.message, type: "error" });
                setError(error.message);
            });
    };

    return (
        <ProfileLayout>
            <div className="profileContent">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
                <div className="contentContainer addgroup-container">
                    <label className="label">
                        { t('profile.admin.group.choseName') }
                        <input
                        type="text"
                        name="groupname"
                        className="input"
                        min={0}
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        />
                    </label>
                    <label className="label">
                    { t('profile.admin.group.maxPeople') }
                        <input
                        type="number"
                        name="maxMembers"
                        className="input"
                        onChange={(e) => setMaxMembers(e.target.value)}
                        value={maxMembers}
                        />
                    </label>
                    {error && <p className="error">{error}</p>}
                    <Button text={ t('profile.admin.group.button') } type="submit" color="blue" className="submit-button" onClick={handleSubmit} disabled={name.length === 0} />
                </div>
            </div>
        </ProfileLayout>
    );
};

export default AddGroup;