import "./ProfileTitleStyle.css"


const ProfileTitle = ({logo, title, subtitle}) => {
    return (
        <div className="TitleContainer">


            <div className="Settings">
            <img src={logo} className="titleIcon" alt="page logo" />
            </div>

            <div className="Line"></div>
            <div>
                <h2 className="Title">{title}</h2>
                <p className="Subtitle">{subtitle}</p>
            </div>
        </div>

    );
}

export default ProfileTitle;