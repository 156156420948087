import React, { useContext, useState } from "react";
import copyIcon from "../../assets/icons/copy.png";
import "./AssignmentCard.css";
import Button from "../button/Button";
import { deleteAssignment, putAssignment } from "../../services/assignmentService";
import { AuthContext } from "../../contexts/AuthContext";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { ToastContext } from "../../contexts/ToastContext";
import toast from "react-hot-toast";

const AssignmentCard = ({ assignment, setError, fetchAssignments }) => {
    const [loading, setLoading] = useState(false);
    const { token } = useContext(AuthContext);
    const { t } = useTranslation();
    const { setToastInstance } = useContext(ToastContext);
    const navigate = useNavigate();

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            setToastInstance({ message: "Link gekopieerd naar clipboard", type: "success" });
        })
            .catch((error) => {
                setToastInstance({ message: "Error copying text to clipboard", type: "error" });
                // Handle error, e.g., show an error message to the user
            });
    };

    const removeAssignment = async () => {
        setLoading(true);
        await deleteAssignment(assignment.assignment_id, token).then((data) => {
            setLoading(false);
            fetchAssignments();
            setToastInstance({ message: t("toasts.assignmentdeleted"), type: "success" });
            setError(null);
        }).catch((error) => {
            setError(error.message);
            setToastInstance({ message: error.message, type: "error" });
            setLoading(false);
        });
    };

    const lockAssignment = async () => {
        setLoading(true);
        await putAssignment(assignment.assignment_id, token).then((data) => {
            setLoading(false);
            fetchAssignments();
            setToastInstance({ message: t("toasts.assignmentlocked"), type: "success" });
            setError(null);
        }).catch((error) => {
            setError(error.message);
            setToastInstance({ message: error.message, type: "error" });
            setLoading(false);
        });
    }

    return (
        <div key={assignment.assignment_id} className="list-item assignment-item">
            <div className="assignment-item__top">
                <h3>{assignment.name} <span className="formName">- {assignment.form_name}</span></h3>
                {assignment.open 
                ? <p className="link" onClick={() => copyToClipboard(`${window.location.hostname}/vragenlijst/${assignment.assignment_id}`)}>
                <span>{`${window.location.hostname}/vragenlijst/${assignment.assignment_id}`}</span>
                <button className="copy-button"><img className="copy-button-img" src={copyIcon} width={30} height={30} /></button>
            </p> : <p className="closed">{ t('profile.admin.assignments.locked') }</p>}
                
            </div>
            <div className="assignment-item__bottom">
                <div className="assignment-item__groups">
                    {assignment.groups.map((group) => (
                        <div className="assignment-item__group">
                            <p key={group.group_id}>{group.name}</p>
                        </div>
                    ))}
                </div>
                <div style={{display: "flex", gap: "1rem"}}>
                    <Button text="Bekijk" color="blue" className="button-small" onClick={() => navigate(`/profile/admin/assignments/${assignment.assignment_id}/gemiddeldes`)}/>
                    {assignment.open && <Button text="Lock" color="blue" className="button-small" onClick={() => lockAssignment(assignment.assignment_id)} />}
                    <Button loading={loading} text={ t('profile.admin.assignments.delete') } color="red" className="button-small" onClick={() => removeAssignment()}/>
                </div>
            </div>
        </div>
    );
};

export default AssignmentCard;
