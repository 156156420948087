import React, { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Input } from "./Input";
import Delete from "../../assets/icons/delete.svg";
import Plus from "../../assets/icons/plusBlack.svg";
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ nestIndex }) => {
  const { register, formState: { errors }  } = useFormContext();
  const { fields: questions, remove, append } = useFieldArray({
    name: `form.blocks[${nestIndex}].questions`,
  });
  const { t } = useTranslation();

  const handleRemove = (index) => {
    remove(index);
  };

  return (
    <div>
      {questions.map((item, index) => {
        return (
          <div key={item.id} className="block-questions">
            <span className="question-index">{(index + 1)}</span>
            <Input
              {...register(`form.blocks[${nestIndex}].questions[${index}].question`, {
                required: t('errors.required'),
                minLength: {
                  value: 3,
                  message: t('errors.lt3'),
                }
              })}
              placeholder={`${ t('profile.admin.addForm.questionPlh') } ${index}`}
              defaultValue={item.question}
            />
            {errors?.form?.blocks?.[nestIndex]?.questions?.[index]?.question && (
              <p className="error">{errors.form.blocks[nestIndex].questions[index].question.message}</p>
            )}

            <button className="formAddButton delete-block-button" type="button" onClick={() => handleRemove(index)}>
              <img
                src={Delete} 
                alt="delete"
              />
            </button>
          </div>
        );
      })}

      <button className="formAddButton add-question-button" title={ t('profile.admin.addForm.addQuestion') } type="button" onClick={() => append({question: ""})}>
        <img
          src={Plus}  
          alt="add"
          style={{marginTop: "8px"}}
        />
      </button>

    </div>
  );
};
